export const legalText = [
  `Pond Network™ Platform (Permian Basin) Log-On Notice and Consent`,

  `You are accessing the Pond Network™ Platform ("Platform"), an information system that is owned and administered by Fortior Solutions, LLC ( "Provider").  By accessing and using the Platform (which includes all information in the Platform, your password to access the Platform, and any device that connects to the Platform), you consent to the following conditions:`,

  `- The Platform may be accessed and used solely by Provider-authorized users.  Disclosure, sharing, distribution, delivery or other provision of the Platform by users to any unauthorized person, entity or agency is strictly prohibited.`,

  `- Access to and use of the Platform is subject to Provider's Pond Network terms and conditions, as may be modified, amended or updated by Provider from time to time.`,

  `- The Platform may be accessed and used solely for physical safety and security purposes, in compliance with applicable legal requirements.`,

  `- The Platform may not be accessed or used in whole or in part as a factor in determining an individual's eligibility for credit, insurance, employment, or any other purpose in connection with which a consumer report may be procured or used under Fair Credit Reporting Act, 15 U.S.C. Section 1681, et seq. ("FCRA"), as amended.`,

  `- The Platform may not be used for any unauthorized or unlawful purpose. The Platform may not be used to mistreat, defame or take retribution against any person; to post any information that violates or infringes a person's rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right; to post any information that constitutes hate speech or that is threatening, sexually explicit or incites violence; or to post an information that promotes racism, bigotry or hatred of any kind against any group or individual.`,

  `- Provider may monitor use of the Platform at any and all times for purposes including for Platform operations, maintenance, security and defense; Platform performance; reviewing authorized access and acceptable use; internal auditing; protecting confidential information and trade secrets; and fulfilling policy and legal requirements and obligations.`,

  `- Provider may monitor, inspect, intercept and/or remove communications and other information stored on the Platform at any time.`,

  `- Use of, and communications and other information stored on, the Platform may be subject to disclosure by Provider to third parties including to local, state and federal law enforcement and to other government agencies, to comply with legal obligations or requirements.`,

  `- Provider reserves all intellectual property rights in the Platform.  Users do not acquire or assume any intellectual property rights to the Platform through their use of it.  `,

  `- Provider shall not be liable for user-posted communications or other user-posted information in the Platform.  Users are solely responsible and liable for the lawfulness, completeness, thoroughness, currentness, validity, reliability and accuracy of all information they post to the Platform. `,

  `- WARNING - Access to the Platform from a public computer or other public device (e.g., libraries, airports, cafes, hotels, etc.) is strictly prohibited.`,
];
