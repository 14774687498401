import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
// import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
// import Table from "../../components/table/Table";
import { useNavigate } from "react-router-dom";
import Incidentstable from "../../components/incidentstable/IncidentsTable";

// 7/16/2023 - changed balance to products

// 7/24/2023 - removed orders and earnings widgets

// 8/19/2023 added imports for table names

// 8/23/2023 - get the authenticated user's ID

import {
  //auth,
  db,
  messaging,
  //messagingToken,
} from "../../firebase";

// 8/28/2023 - changed admin list import

import {
  pne001AdminList,
  pnm001AdminList,
  pns001AdminList,
  pne001ReadOnlyList,
  pnm001ReadOnlyList,
  pns001ReadOnlyList,
} from "../../adminlist";

import { useEffect, useState } from "react";
import { getToken } from "firebase/messaging";
import Map from "../../components/map/Map";

//import { collection } from "firebase/firestore";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";

// 9/3/2023
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";

const Home = ({ tableName }) => {
  const navigate = useNavigate();

  const [showAllIncidents, setShowAllIncidents] = useState(false);

  const clientName = localStorage.getItem("clientName");

  // 8/23/2023
  const [hasReadAccess, setHasReadAccess] = useState(true);
  const [data, setData] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("user"));

  const currentUserUID = currentUser.uid;

  // 9/3/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  //console.log(isSidebarOpen)

  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/2/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1200
  );
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200);

  // Check if current user is in the admin list
  useEffect(() => {
    // If the current user is not in the list of admins or the list of limited access users for that particular DB, then redirect to the warning page
    if (
      (clientName === "PN-E001" &&
        !pne001AdminList.includes(currentUserUID) &&
        !pne001ReadOnlyList.includes(currentUserUID)) ||
      (clientName === "PN-M001" &&
        !pnm001AdminList.includes(currentUserUID) &&
        !pnm001ReadOnlyList.includes(currentUserUID)) ||
      (clientName === "PN-S001" &&
        !pns001AdminList.includes(currentUserUID) &&
        !pns001ReadOnlyList.includes(currentUserUID))
    ) {
      setHasReadAccess(false);

      navigate("/error");
    } else {
      setHasReadAccess(true);
      navigate("/");
    }

    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      }
    });

    // Add the public key generated from the console here.

    getToken(messaging, {
      vapidKey:
        "BHRpztMbOta-14kHn2SbNUHHcrTvg8f7VxZjbnXpoltEc_OXRhPUTEdoRkvxQWtQYyFo9xOeYeGZtHXOsF0o3tU",
    })
      .then((currentToken) => {
        if (currentToken) {
          // Store the token in local storage
          localStorage.setItem("notificationsToken", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }, []);

  // Mobile device useeffect (<= 768)
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Medium device useEffect
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Large device useEffect
  useEffect(() => {
    // Function to update state based on window size
    // Auto hide the sidebar as we already have one
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth > 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(hasReadAccess);
  console.log(isLargeDevice);

  // Get the incident data and retrieve the addresses for passing to the map
  // Fetch the data once
  useEffect(() => {
    const clientName = localStorage.getItem("clientName");
    // 8/17/2023 - Define the full table name as Clients/clientName/tableName.
    const fullTableName = "Clients/" + clientName + "/" + tableName;

    console.log(fullTableName);
    // LISTEN (REAL TIME DATA)
    const unsub = onSnapshot(
      collection(db, fullTableName),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // 8/13/2023 - updated code so that the data for an individual incident is stored in a variable, then the timestamp is updated
          // After this, add the incident data to the list
          let doc_data = { id: doc.id, ...doc.data() };
          //console.log(doc_data)
          doc_data["addedTimeStamp"] = doc_data["addedTimeStamp"]
            ?.toDate()
            .toLocaleString(); // either get the timestamp or use a null value

          doc_data["timeStamp"] = doc_data["timeStamp"]
            ?.toDate()
            .toLocaleString(); // either get the timestamp or use a null value
          list.push(doc_data);
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      unsub();
    };
  }, []);

  let allIncidentLocations = [];
  let allIncidentIDs = [];

  console.log(data);

  if (data !== undefined && data !== null) {
    allIncidentLocations = data.map((incident) => {
      return incident.incidentLocation;
    });

    allIncidentIDs = data.map((incident) => {
      return incident.id;
    });
  }

  console.log(allIncidentIDs);

  console.log(data);

  // Home page HTML
  return (
    <div className="home">
      {/* <Sidebar /> */}

      {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
      {isMobile || isMediumDevice ? (
        <div className="hamburger-icon" onClick={handleSidebar}>
          <MenuIcon />
        </div>
      ) : (
        // Must be a large device with a screen width > 1200px

        <Sidebar />
      )}

      {(isMobile || isMediumDevice) && isSidebarOpen && <Sidebar />}
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="bad_actors" />
          <Widget type="incidents" />
        </div>

        <div className="mapWidget">
          {/* <Map addresses={[]} /> */}
          <Button
            onClick={() => setShowAllIncidents(!showAllIncidents)}
            className="showPredictionsButton"
          >
            Show Incidents
          </Button>
          {showAllIncidents ? (
            <Map
              addresses={allIncidentLocations}
              incidentIDs={allIncidentIDs}
            />
          ) : (
            <Map addresses={[]} />
          )}
        </div>
        <div className="charts">
          {/* <Featured /> */}
          <Chart
            title="Incident Predictions (based on past data)"
            aspect={2 / 1}
            chart_width="99%"
          />
        </div>

        <div className="listContainer">
          <div className="listTitle">Latest Incidents</div>
          {/* <Table /> */}
          <Incidentstable tableName={tableName} />
        </div>
      </div>
    </div>
  );
};

export default Home;
