// 7/14/2023
// Edited to use dynamic data from Firestore

// 7/15/2023 - edited to listen to realtime data updates

// 7/28/2023 - cloned from Datatable.jsx to provide workaround

import "./incidentstable.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { badActorColumns, incidentColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";

// 7/14/2023 and 8/28/2023
import {
  //auth,
  db,
  sendEmail,
  sendNotification,
} from "../../firebase";

//const user = auth.currentUser;

//console.log(user)

// 7/27/2023 - pass in a type prop to determine whether to show bad actors or incidents
const IncidentsTable = ({ tableName }) => {
  console.log("Table name is " + tableName);
  // 7/14/2023 - change userRows to an empty array
  const [data, setData] = useState([]);

  // 8/17/2023 - added call to localstorage
  const clientName = localStorage.getItem("clientName");
  // 8/17/2023 - Define the full table name as Clients/clientName/tableName.
  const fullTableName = "Clients/" + clientName + "/" + tableName;

  // Fetch the data once
  useEffect(() => {
    console.log(fullTableName);
    // LISTEN (REAL TIME DATA)
    const unsub = onSnapshot(
      collection(db, fullTableName),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // 8/13/2023 - updated code so that the data for an individual incident is stored in a variable, then the timestamp is updated
          // After this, add the incident data to the list
          let doc_data = { id: doc.id, ...doc.data() };
          //console.log(doc_data)
          doc_data["addedTimeStamp"] = doc_data["addedTimeStamp"]
            ?.toDate()
            .toLocaleString(); // either get the timestamp or use a null value

          doc_data["timeStamp"] = doc_data["timeStamp"]
            ?.toDate()
            .toLocaleString(); // either get the timestamp or use a null value
          list.push(doc_data);
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      unsub();
    };
  }, []);
  //console.log(data)

  // From previous tutorial - handleDelete function
  // It filters data
  // 7/15/2023 - updated to delete data and filter data
  // 7/25/2023 - updated to delete from bad_actors
  const handleDelete = async (id) => {
    try {
      // Define the full table name as Clients/clientName/tableName.
      const fullTableName = "Clients/" + clientName + "/" + tableName;
      // Delete the doc containing the bad actor
      await deleteDoc(doc(db, fullTableName, id));
      setData(data.filter((item) => item.id !== id));
      sendNotification(
        "Change in Database",
        `An entry was deleted from ${fullTableName}`
      );
      sendEmail("template_zaxngdi");
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* 7/20/2023 Changed "/users/test" to  {`/users/${params.row.id}`} */}
            <Link
              to={
                tableName === "bad_actors"
                  ? `/users/${params.row.id}`
                  : `/incidents/${params.row.id}`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={
                tableName === "bad_actors"
                  ? `/users/${params.row.id}/edit`
                  : `/incidents/${params.row.id}/edit`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="editButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {tableName === "bad_actors" ? "Add New Bad Actor" : "Add New Incident"}
        <Link
          to={tableName === "bad_actors" ? "/users/new" : "/incidents/new"}
          className="link"
        >
          Add New
        </Link>
      </div>
      {/* Changed columns to use either userColumns or incidentColumns */}
      {/* 9/4/2023 - added download section */}
      <DataGrid
        className="datagrid"
        rows={data}
        columns={
          tableName === "bad_actors"
            ? badActorColumns.concat(actionColumn)
            : incidentColumns.concat(actionColumn)
        }
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        components={{ Toolbar: GridToolbar }}
      />
    </div>
  );
};

export default IncidentsTable;
