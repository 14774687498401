import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
//import Chart from "../../components/chart/Chart";
//import List from "../../components/table/Table";

// 8/26/2023 - new import for showing multiple person of interest images
import ImageSlider from "../../components/imageslider/ImageSlider";

// 7/20/2023
import { Link, useParams } from "react-router-dom";

// 7/21/2023
import {
  collection,
  //getDocs,
  getDoc,
  //deleteDoc,
  doc,
  onSnapshot,
  //query,
  //where,
} from "firebase/firestore";
import {
  //auth,
  db,
  badActorTableName,
  incidentsTableName,
} from "../../firebase";
import { useEffect, useState } from "react";
//import IncidentsTable from "../../components/incidentstable/IncidentsTable";
import { DataGrid } from "@mui/x-data-grid";
import {
  //badActorColumns,
  incidentColumns,
} from "../../datatablesource";
import Videos from "../../components/videos/Videos";
import AiWidget from "../../components/aiwidget/AiWidget";

// 9/1/2023
import MenuIcon from "@mui/icons-material/Menu";

// 9/13/2023 - map import
import Map from "../../components/map/Map";

// 8/17/2023 - added call to localstorage
//const clientName = localStorage.getItem("clientName")

// 7/28/2023 - added a type prop to determine which content to show (person of interests or incidents)
const Single = ({ type }) => {
  // Set the data to display on the page
  const [data, setData] = useState(null);

  // 8/20/2023 - used for setting the incident data to display
  const [incidentData, setIncidentData] = useState([]);

  // 9/5/2023 - used for getting the person of interest data related to the incident to display
  //const [badActorData, setBadActorData] = useState([]);

  // 9/1/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // 9/24/2023
  // Loading state - set this while data is being received
  const [loading, setLoading] = useState(false);

  // 10/11/2023 - error
  const [error, setError] = useState(false);

  //console.log(isSidebarOpen)

  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/2/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1200
  );
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200);

  // Get the parameters from the URL.
  // params.userId contains the user's ID
  // This cannot be called inside useEffect(), so we write a helper called getParams() below
  const params = useParams();

  // Helper function to return the user ID from the URL
  const getParams = () => {
    //return params.userId;
    if (type === "bad_actors") {
      return params.badActorId;
    } else {
      return params.incidentId;
    }
  };

  // Mobile device useeffect (<= 768)
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Medium device useEffect
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Large device useEffect
  useEffect(() => {
    // Function to update state based on window size
    // Auto hide the sidebar as we already have one
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth > 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Use Firebase to query the needed information
  //console.log(params)
  useEffect(() => {
    const fetchUserData = async () => {
      const id = getParams();
      const clientName = localStorage.getItem("clientName");
      try {
        setLoading(true);
        // 7/25/2023 - changed to bad_actors
        // Determine which table to pull from based on the prop type (bad_actors or incidents)
        let tableName = badActorTableName;
        console.log(type);
        if (type !== "bad_actors") {
          tableName = incidentsTableName;
        }

        // Define the full table name as Clients/clientName/tableName.
        const fullTableName = "Clients/" + clientName + "/" + tableName;
        console.log(fullTableName);

        // 8/17/2023 - changed tableName to fullTableName
        const docRef = doc(db, fullTableName, id); // pass the user ID from the URL to Firebase
        const querySnapshot = await getDoc(docRef); // Query the collection for the specific user
        console.log(querySnapshot);
        //console.log("Getting person of interest data");
        console.log(querySnapshot.data());
        if (
          querySnapshot.data() === undefined ||
          querySnapshot.data() === null // no data found
        ) {
          setError(true);
        }
        setData(querySnapshot.data()); // get the response back and the user data
        return querySnapshot.data();
      } catch (error) {
        setLoading(false);
        console.log("There has been an error");
        console.log(error);
      }
    };

    // Get the user data
    // return () => {
    //   fetchUserData();
    // }
    fetchUserData();
  }, []);

  // 9/24/2023 - call this when data is acquired
  useEffect(() => {
    console.log(data);
    setLoading(false);
  }, [data]);

  // Second useEffect -> Get the table of incidents linked to this person of interest
  useEffect(() => {
    const clientName = localStorage.getItem("clientName");
    const badActorId = getParams();
    // Define the full table name as Clients/clientName/tableName.
    const fullTableName = "Clients/" + clientName + "/" + incidentsTableName;
    const fetchIncidentData = onSnapshot(
      collection(db, fullTableName),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // 8/13/2023 - updated code so that the data for an individual incident is stored in a variable, then the timestamp is updated
          // After this, add the incident data to the list
          // 8/20/2023 - get the incident list and filter by only the incidents the person of interest is involved in
          if (doc.data().badActorID === badActorId) {
            let doc_data = { id: doc.id, ...doc.data() };
            console.log("Getting incidents data");
            console.log(doc_data);
            doc_data["addedTimeStamp"] = doc_data["addedTimeStamp"]
              ?.toDate()
              .toLocaleString(); // 9/9/2023 - either get the timestamp or use a null value
            doc_data["timeStamp"] = doc_data["timeStamp"]
              ?.toDate()
              .toLocaleString(); // either get the timestamp or use a null value
            list.push(doc_data);
          }
        });
        setIncidentData(list);
      },
      (error) => {
        console.log(error);
        setError(true);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      fetchIncidentData();
    };
  }, []);

  // // 9/5/2023 - for later
  // // query all the data related to the person of interest linked to the displayed incident
  // useEffect(() => {
  //   const fetchData = async() => {

  //     // get data about the
  //     // If today is July 16, get data between June 16 and May 16
  //     const fullTableName = "Clients/" + clientName + "/" + badActorTableName + "/" + data.badActorID
  //     console.log(fullTableName)
  //     const singleBadActorQuery = query(
  //       collection(db, fullTableName),
  //     );

  //     const singleBadActorData = await getDocs(singleBadActorQuery);

  //     setBadActorData(singleBadActorData)

  //   }
  //   fetchData();
  // }, [])

  // console.log(badActorData.docs)

  //console.log("Incident data")
  //console.log(incidentData)

  //console.log(data)
  let dateAdded = null;
  let dateUpdated = null; // 9/9/2023 - separate field
  if (data !== undefined && data !== null) {
    // console.log(data)
    // console.log(data.timeStamp)
    dateAdded = data.addedTimeStamp.toDate().toLocaleString(); // string conversion is necessary to avoid errors when displaying the timestamp. 8/19/2023 - changed to only show date and time
    dateUpdated = data.timeStamp.toDate().toLocaleString();
    // console.log(dateAdded)
    // console.log(data)
  }

  // 9/15/2023 - Array containing the list of all incident addresses for a person of interest.
  let allIncidentLocations = [];

  // 10/17/2023
  let allIncidentIDs = [];

  console.log(data);

  // On the person of interest page, show all incident addresses.
  if (type === "bad_actors") {
    if (
      incidentData !== undefined &&
      incidentData !== null &&
      incidentData.length > 0
    ) {
      allIncidentLocations = incidentData.map((incident) => {
        return incident.incidentLocation;
      });
      console.log(incidentData);

      // incident ids = use for link in the map marker
      allIncidentIDs = incidentData.map((incident) => {
        return incident.id;
      });
    }
    // On the incident page, show only the listed incident address.
  } else if (type === "incidents") {
    if (data !== undefined && data !== null) {
      allIncidentLocations = [data.incidentLocation];

      // The link to the incident in the map marker should link back to itself
      const incidentID = getParams();
      allIncidentIDs = [incidentID];
    }
  }
  // console.log(incidentData);
  // console.log(allIncidentIDs);

  // console.log("Is there an error?");
  // console.log(error);

  return (
    <div className="single">
      {/* Hamburger icon */}
      {/* <div className="hamburger-icon" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <MenuIcon/>
      </div> */}

      {/* <Sidebar /> */}

      {/* Conditional rendering of Sidebar */}
      {/* {isSidebarOpen && <Sidebar />} */}

      {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
      {isMobile || isMediumDevice ? (
        <div className="hamburger-icon" onClick={handleSidebar}>
          <MenuIcon />
        </div>
      ) : (
        // Must be a large device with a screen width > 1200px

        <Sidebar />
      )}

      {(isMobile || isMediumDevice) && isSidebarOpen && <Sidebar />}

      {/* <div className="singleContainer"> */}
      <div className={`singleContainer ${isSidebarOpen ? "with-sidebar" : ""}`}>
        <Navbar />
        <div className="top">
          <div className="left">
            {/* 7/28/2023 - changed Link to to point to one of two URLs */}
            <Link
              to={
                type === "bad_actors"
                  ? `/bad_actors/${getParams()}/edit`
                  : `/incidents/${getParams()}/edit`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="editButtonSingle">Edit</div>
            </Link>
            <h1 className="title">
              {type === "bad_actors"
                ? "Information on Person of Interest"
                : "Information on Incident"}
            </h1>
            <div className="item">
              {data !== undefined && data !== null && (
                <img src={data.img} alt="" className="itemImg" />
              )}

              {/* Loading text to show while data is loading - change to error text if no data was found*/}
              {(data === undefined || data === null) && (
                <h1 className="itemTitle">
                  {error ? "No data found" : "Loading..."}
                </h1>
              )}

              {/* {error && <h1 className="itemTitle">No data found</h1>} */}

              {type === "bad_actors" && data !== undefined && data !== null && (
                //
                <div className="details">
                  {/* 7/28/2023 - updated code to show info based on type (bad_actor or incidents)*/}
                  {data !== undefined && data !== null && (
                    <h1 className="itemTitle">{data.displayName}</h1>
                  )}

                  <div className="detailItem">
                    <span className="itemKey">Gender:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.gender}</span>
                    )}
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Age:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.age}</span>
                    )}
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Address:</span>

                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.address}</span>
                    )}
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.country}</span>
                    )}
                  </div>

                  {/* Date added field field - 9/9/2023 */}
                  <div className="detailItem">
                    <span className="itemKey">Date Added:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{dateAdded}</span>
                    )}
                  </div>

                  {/* Timestamp field - 8/13/2023 */}
                  <div className="detailItem">
                    <span className="itemKey">Last Updated:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{dateUpdated}</span>
                    )}
                  </div>

                  {/* 9/29/2023 - Phone Number - write N/A if not available */}
                  <div className="detailItem">
                    <span className="itemKey">Phone Number: </span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">
                        {data.phone !== undefined && data.phone !== null
                          ? data.phone
                          : "N/A"}
                      </span>
                    )}
                  </div>

                  {/* 9/29/2023 - Employer - write N/A if not available */}
                  <div className="detailItem">
                    <span className="itemKey">Employer: </span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">
                        {data.employer !== undefined && data.employer !== null
                          ? data.employer
                          : "N/A"}
                      </span>
                    )}
                  </div>

                  {/* 9/7/2023 - person of interest's history */}
                  <div className="detailItem">
                    <span className="itemKey">Offense History:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.history}</span>
                    )}
                    {/* If no offense history is available, show that the person of interest was added on the above date */}
                    {(data.history === undefined || data.history === null) && (
                      <span className="itemValue">
                        {dateAdded}: Added for {data.badAct}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {/* Incidents */}
              {type === "incidents" && data !== undefined && data !== null && (
                <div className="details">
                  {/* <h1 className="itemTitle">Jane Doe</h1> */}
                  {/* 7/28/2023 - updated code to show info based on type (bad_actor or incidents)*/}
                  {data !== undefined && data !== null && (
                    <h1 className="itemTitle">{data.victim}</h1>
                  )}
                  <div className="detailItem">
                    <span className="itemKey">Crime Category:</span>
                    {/* <span className="itemValue">janedoe@gmail.com</span> */}
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.crimeCategory}</span>
                    )}
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Crime Method:</span>
                    {/* <span className="itemValue">+1 2345 67 89</span> */}
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.crimeMethod}</span>
                    )}
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Duration (seconds):</span>

                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.duration}</span>
                    )}
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Number of Perps:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.numberOfPerps}</span>
                    )}
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Stolen Items:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.stolenItems}</span>
                    )}
                  </div>

                  {/* Date added field - 9/9/2023 */}
                  <div className="detailItem">
                    <span className="itemKey">Date of Incident:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{dateAdded}</span>
                    )}
                  </div>

                  {/* Timestamp field - 8/13/2023 */}
                  <div className="detailItem">
                    <span className="itemKey">Last Updated:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{dateUpdated}</span>
                    )}
                  </div>

                  {/* Incident Location - 9/14/2023 */}
                  <div className="detailItem">
                    <span className="itemKey">Incident Location:</span>
                    {data !== undefined && data !== null && (
                      <span className="itemValue">{data.incidentLocation}</span>
                    )}
                  </div>

                  {/* person of interest ID field for incident - 9/5/2023 */}
                  {/* 10/6/2023 - added link to person of interest.*/}
                  <div className="detailItem">
                    <span className="itemKey">Person of Interest's ID:</span>
                    {data !== undefined && data !== null && (
                      <Link
                        to={`/bad_actors/${data.badActorID}`}
                        onClick={() => {
                          window.location.href = `/bad_actors/${data.badActorID}`;
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <span className="itemValue">{data.badActorID}</span>
                      </Link>
                    )}
                    {/* If no person of interest id was selected during upload, show N/A */}
                    {(data.badActorID === undefined ||
                      data.badActorID === null) && (
                      <span className="itemValue">N/A</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="right">
            {/* Show the incident location(s) */}
            <div className="mapWidget">
              {data !== undefined && data !== null && (
                // <Map addresses={[data.incidentLocation]}/>
                <Map
                  addresses={allIncidentLocations}
                  incidentIDs={allIncidentIDs}
                />
              )}
            </div>
            <AiWidget />
          </div>
          {/* <div className="mostright">
          </div> */}
        </div>

        {/* Middle widget */}
        <div className="middle">
          {/* If there is person of interest data, show the images */}
          {data !== undefined && data !== null && (
            <ImageSlider badActorImages={data.moreImgs} type={type} />
          )}
        </div>

        {/* INCIDENTS ONLY - show videos if present */}
        {type === "incidents" &&
          data !== undefined &&
          data !== null &&
          data.video !== undefined && (
            <div className="middle-bottom">
              <Videos incidentVideos={data.video} />
            </div>
          )}

        <div className="bottom">
          {/* 8/20/2023 - replace this table with a table of incidents linked to this person of interest */}
          {type === "bad_actors" ? (
            <h1 className="title">
              Incidents involving this person of interest
            </h1>
          ) : (
            <h1 className="title">
              Person of interest involved in this incident
            </h1>
          )}
          {type === "bad_actors" && data !== undefined && data !== null ? (
            <div className="datatable">
              {/* Changed columns to use either userColumns or incidentColumns */}
              <DataGrid
                className="datagrid"
                rows={incidentData}
                columns={incidentColumns}
                pageSize={9}
                rowsPerPageOptions={[9]}
                checkboxSelection
              />
            </div>
          ) : (
            "Upcoming person of interest Data related to this incident will be shown here."
          )}
        </div>
      </div>
    </div>
  );
};

export default Single;
