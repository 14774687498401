import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import Edit from "./pages/edit/Edit";
import Error from "./pages/error/Error";

// 7/28/2023 - added type for Single pages
// 7/29/2023 - changed user to badActor

// 7/27/2023 added imports for table names
import { badActorTableName, incidentsTableName } from "./firebase";
import ContactForm from "./pages/contactform/ContactForm";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  // THe current user - // 7/12/2023
  const { currentUser } = useContext(AuthContext)

  //console.log(currentUser)

  // Navigate to the login page if no user is logged in
  // Else - > go to the home page
  const RequireAuth = ({ children }) => {
    // 7/12/2023 - prevent unauthenticated users from viewing the app
    return currentUser ? (children) : <Navigate to="/login"/>;
  }

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route index element={<RequireAuth><Home tableName={incidentsTableName}/></RequireAuth>} />
            {/* Error page */}
            <Route 
                path="error" 
                element={
                  <RequireAuth>
                    <Error/>
                  </RequireAuth>
                } 
              />
              {/* Contact form 8/24/2023 */}
              <Route 
                path="contact" 
                element={
                  <RequireAuth>
                    <ContactForm/>
                  </RequireAuth>
                } 
              />
            <Route path="bad_actors">
              <Route index element={
                <RequireAuth>
                  <List tableName={badActorTableName}/>
                </RequireAuth>
              } />
              <Route 
                path=":badActorId" 
                element={
                  <RequireAuth>
                    <Single type="bad_actors"/>
                  </RequireAuth>
                } 
              />
              <Route 
                path=":badActorId/edit" 
                element={
                  <RequireAuth>
                    <Edit inputs={userInputs} title="Edit Bad Actor"/>
                  </RequireAuth>
                } 
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    {/* 7/25/2023 - Changed title */}
                    <New inputs={userInputs} title="Add New Bad Actor" />
                  </RequireAuth>
              }
              />
            </Route>
            <Route path="incidents">
              <Route 
                index 
                element={
                  <RequireAuth>
                    <List tableName={incidentsTableName}/>
                  </RequireAuth>
                } 
              
              />
              <Route path=":incidentId" element={
                <RequireAuth>
                  <Single type="incidents"/>
                </RequireAuth>
                } 
              />
              <Route 
                path=":incidentId/edit" 
                element={
                  <RequireAuth>
                    <Edit inputs={productInputs} title="Edit Incident"/>
                  </RequireAuth>
                } 
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={productInputs} title="Add New Incident" />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
