import "./sidebar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";

// 8/18/2023 - for contact form
import ContactMailIcon from "@mui/icons-material/ContactMail";

// 7/24/2023 - changed sidebar title to The Pond
// Changed Users to Bad Actors and Products to Incidents
// Removed the Orders and earnings lists

// 7/28/2023 changed user route to bad actor toue

// 7/16/2023
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

const Sidebar = () => {
  const navigate = useNavigate();

  const clientName = localStorage.getItem("clientName");

  // 7/16/2023 - log the user out and clear the local storage
  const handleLogout = (e) => {
    // // 9/20/2023 - this is supposed to remove the flowise chatbot, but it doesn't work
    const script2 = document.getElementsByTagName("flowise-chatbot");
    for (let i = script2.length - 1; i >= 0; --i) {
      document.body.removeChild(script2[i]);
    }

    const script3 = document.getElementsByTagName("flowise-chatbot");
    for (let i = script3.length - 1; i >= 0; --i) {
      document.body.removeChild(script3[i]);
    }

    // Log out of the app
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch({ type: "LOGOUT" }); // 7/12/2023
        localStorage.removeItem("user"); // This is critical - localStorage allows the user to refresh the page
        localStorage.removeItem("clientName"); // Remove the institution name and reset it for the next user
        console.log("User signed out");
        // 9/20/2023 - this is supposed to remove the flowise chatbot, but it doesn't work
        const script = document.getElementsByTagName("flowise-chatbot");
        for (let i = script.length - 1; i >= 0; --i) {
          document.body.removeChild(script[i]);
        }

        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  const { dispatch } = useContext(DarkModeContext);

  return (
    <div className="sidebar">
      <div className="top">
        <Link
          to="/"
          state={{ clientName: clientName }}
          style={{ textDecoration: "none" }}
        >
          <span className="logo">The Pond Network</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">{clientName}</p>
          <Link
            to="/bad_actors"
            state={{ clientName: clientName }}
            style={{ textDecoration: "none" }}
          >
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Persons of Interest</span>
            </li>
          </Link>
          <Link to="/incidents" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Incidents</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          {/* 8/18/2023 - added link to contact form - implementation on 8/24/2023 */}
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <li>
              <ContactMailIcon className="icon" />
              <span>Contact Admin</span>
            </li>
          </Link>
          <li onClick={handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
