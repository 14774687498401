// 7/12/2023
// auth context

import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

// When we refresh our app, the user is null
// After we log in, we need to store the user in local storage
const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  //console.log(state.currentUser)

  // Save user state in the local storage so we don't need to log in each time we refresh the page
  useEffect(()=>{
    //localStorage.setItem("user", JSON.stringify(state.currentUser)) // returns an object, so we need to write a string
    // This does not work yet
    if (state.currentUser) {
      localStorage.setItem("user", JSON.stringify(state.currentUser));
    } else {
      localStorage.removeItem("user");
    }
  },[state.currentUser])

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
