// 8/26/2023
// Widget to show multiple images via a slider.

import "./imageslider.scss";

// Material UI arrow icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useState } from "react";

const ImageSlider = ({ badActorImages, type }) => {
  // useStates
  const [current, setCurrent] = useState(0);

  // Determine how many images are present

  const length = badActorImages?.length;

  // Functions for advancing and reversing the slideshow

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  //console.log(type)

  // Show nothing if the array is not an array or it is empty
  if (!Array.isArray(badActorImages) || badActorImages.length <= 0) {
    return (
      <div className="imageslider">
        <span className="slidertitle">{`There are no extra images of this ${
          type === "bad_actors" ? "person of interest" : "incident"
        }`}</span>
        <img
          src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          className="image"
          alt="placeholder"
        />
      </div>
    );
  }

  // Using className = title for the span leads to the title scss properties from single.scss being applied and overriding any changes in imageslider.scss
  return (
    <div className="imageslider">
      <span className="slidertitle">
        {`See more images of this ${
          type === "bad_actors" ? "person of interest" : "incident"
        }`}
      </span>
      {length > 1 && (
        <ArrowBackIcon className="left-arrow" onClick={prevSlide} />
      )}
      {length > 1 && (
        <ArrowForwardIcon className="right-arrow" onClick={nextSlide} />
      )}

      {badActorImages.map((url, index) => (
        <div
          className={index === current ? "slide active" : "slide"}
          key={index}
        >
          {index === current && (
            <img src={url} alt="bad actor" className="image" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageSlider;
