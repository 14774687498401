import React, { useEffect, useState } from "react";
import "./map.scss";

// useJsAPILoader is a hook that provides us with a variable that checks whether the map is laoded or not
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";

// Location of the Permian Basin - hardcoded.
const center = { lat: 32.5, lng: -103 };
const centerAddress = "Permian Basin, Texas 79714";

// Definition of the Map component
// addresses is a list of real addresses that will be converted to coordinates in the format
// {lat: latitude, long: longitude}
// Each address will be fed to the Geocoding API to return a coordinate, which will be plugged into the Marker object to show it on the map.
const Map = ({ addresses, incidentIDs }) => {
  console.log(addresses);
  // State variable to control when to go back to the marker
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  // State variable to set the marker data
  const [markers, setMarkers] = useState([]);
  const [marker, setMarker] = useState({});
  const [selectedMarker, setSelectedMarker] = useState(null);

  console.log(map, marker);

  // Load the script from the CDN in the JS Maps API using the provided key
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const getLatLong = async (address) => {
    const apiURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await fetch(apiURL);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setMarker(location); // Assuming setMarker is a React useState setter
        return location;
      }
    } catch (error) {
      console.error("Error fetching geocoding results:", error);
    }

    return null;
  };

  // Get the marker data on first load
  // Insert the addresses variable as a dependency so that the markers always appear on first load
  useEffect(() => {
    (async () => {
      const coordsPromises = addresses.map((address) => getLatLong(address));
      const coordsArray = await Promise.all(coordsPromises);
      const filteredCoords = coordsArray.filter((coord) => coord !== null); // Remove any null values
      //console.log(filteredCoords);
      //console.log(filteredCoords.slice(-2));
      setMarkers(filteredCoords.slice(-4));
    })();
  }, [addresses]);

  // Get only the last two elements

  // Map is loading, show a skeleton page
  if (!isLoaded) {
    return <p>Loading...</p>;
  }

  // If a marker is clicked, show an info window with the incident address
  const handleMarkerClick = (markerPosition) => {
    setSelectedMarker(markerPosition);
  };

  console.log(markers);

  const lastAddresses = addresses.slice(-4);

  let lastIncidentIDs = incidentIDs;
  if (incidentIDs !== undefined) {
    lastIncidentIDs = incidentIDs.slice(-4);
  } else {
    lastIncidentIDs = ["N/A"];
  }

  return (
    <div className="map">
      <div className="top">
        {/* 9/4/2023 - retitled the map widget */}
        <h1 className="title">Incident Locations</h1>
      </div>

      {/* Google Map Box */}
      {/* Use options to disable or enable zoom, streetview, map, and full screen */}
      {/* 9/30/2023 - added code to auto center on the incident's location if there is one, otherwise zoom in on the center. */}
      <div className="featuredMap">
        <GoogleMap
          center={
            markers !== undefined && markers.length === 1 ? markers[0] : center
          }
          zoom={markers !== undefined && markers.length === 1 ? 15 : 10} // Zoom in on the incident location if there is one, otherwise stay zoomed out at the center
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker
            icon={
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
            }
            position={center}
            key="center"
            onClick={() => setSelectedMarker(center)}
          />

          {selectedMarker === center && (
            <InfoWindow
              position={selectedMarker}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <p>{centerAddress}</p>
              </div>
            </InfoWindow>
          )}

          {/* Show the real addresses if there are included */}
          {markers !== undefined &&
            markers.map((coord, i) => (
              <React.Fragment>
                <Marker
                  position={coord}
                  key={`${i}`}
                  onClick={() => setSelectedMarker(coord)}
                />
                {selectedMarker === coord && (
                  <InfoWindow
                    position={selectedMarker}
                    onCloseClick={() => setSelectedMarker(null)}
                    key={`${i + "a"}`}
                  >
                    <div>
                      <Link
                        to={`/incidents/${lastIncidentIDs[i]}`}
                        onClick={() => {
                          window.location.href = `/incidents/${lastIncidentIDs[i]}`;
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <span className="itemValue">Link to Incident</span>
                      </Link>
                      <p>{lastAddresses[i]}</p>
                    </div>
                  </InfoWindow>
                )}
              </React.Fragment>
            ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Map;
