// 7/14/2023
// Edited to use dynamic data from Firestore

// 7/15/2023 - edited to listen to realtime data updates

import "./datatable.scss";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { badActorColumns, incidentColumns } from "../../datatablesource";
import {
  Link,
  //useLocation
} from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";

// 7/14/2023
import { db, sendEmail } from "../../firebase";

// 8/28/2023
import { sendNotification } from "../../firebase";
//import { Checkbox } from "@mui/material";
import DeleteModal from "../deletemodal/DeleteModal";

// 7/27/2023 - pass in a type prop to determine whether to show bad actors or incidents
const Datatable = ({ tableName }) => {
  console.log("Table name is " + tableName);
  // 7/14/2023 - change userRows to an empty array
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  // 9/11/2023
  // Determine whether to show the delete modal
  // false = don't show
  // true = show
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    localStorage.removeItem("deleteId");
  };

  const openModal = (id) => {
    setOpen(true);
    localStorage.setItem("deleteId", id);
  };

  // 8/16/2023 -> added useLocation import
  //let { state } = useLocation();

  const clientName = localStorage.getItem("clientName");

  //console.log(clientName)

  // Fetch the data once
  useEffect(() => {
    const fullTableName = "Clients/" + clientName + "/" + tableName;
    console.log(fullTableName);
    // LISTEN (REAL TIME DATA)
    const unsub = onSnapshot(
      collection(db, fullTableName),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          // 8/13/2023 - updated code so that the data for an individual bad actor is stored in a variable, then the timestamp is updated
          // After this, add the bad actor data to the list
          let doc_data = { id: doc.id, ...doc.data() };
          // 9/9/2023 - added initial add date
          doc_data["addedTimeStamp"] = doc_data["addedTimeStamp"]
            .toDate()
            .toLocaleString();
          doc_data["timeStamp"] = doc_data["timeStamp"]
            .toDate()
            .toLocaleString();
          list.push(doc_data);
        });
        setData(list);
      },
      (error) => {
        setError(true);
        console.log("An error has occurred");
        console.log(error);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      unsub();
    };
  }, []);
  console.log(data);

  // From previous tutorial - handleDelete function
  // It filters data
  // 7/15/2023 - updated to delete data and filter data
  // 7/25/2023 - updated to delete from bad_actors
  const handleDelete = async (id) => {
    // 8/17/2023 - defined a full table name and changed tableToEdit to fullTableName
    const fullTableName = "Clients/" + clientName + "/" + tableName;
    try {
      // Delete the doc containing the bad actor
      await deleteDoc(doc(db, fullTableName, id));
      setData(data.filter((item) => item.id !== id));
      sendNotification(
        "Change in Database",
        `An entry was deleted from ${fullTableName}`
      );
      sendEmail("template_zaxngdi");
      // Close the modal window
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  // 9/12/2023 - duplicated form handleDelete - this will run automatically for every entry that is older than a certain date/time
  const handleAutoDelete = async (id, thresholdSeconds) => {
    // 8/17/2023 - defined a full table name and changed tableToEdit to fullTableName
    const fullTableName = "Clients/" + clientName + "/" + tableName;
    try {
      // Delete the doc containing the bad actor
      await deleteDoc(doc(db, fullTableName, id));
      setData(data.filter((item) => item.id !== id));
      sendNotification(
        "Change in Database",
        `An entry was automatically deleted from ${fullTableName} because it is older than ${thresholdSeconds} seconds.`
      );
      sendEmail("template_zaxngdi");
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* 7/20/2023 Changed "/users/test" to  {`/users/${params.row.id}`} */}
            <Link
              to={
                tableName === "bad_actors"
                  ? `/bad_actors/${params.row.id}`
                  : `/incidents/${params.row.id}`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <Link
              to={
                tableName === "bad_actors"
                  ? `/bad_actors/${params.row.id}/edit`
                  : `/incidents/${params.row.id}/edit`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="editButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              //onClick={() => handleDelete(params.row.id)}
              onClick={() => {
                openModal(params.row.id);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  // Auto delete entries that are older than a prescribed threshold (i.e. 30 days)
  const times = data.map((entry) => {
    let diff = Date.now() / 1000 - Date.parse(entry.addedTimeStamp) / 1000;
    console.log(diff);
    return { id: entry.id, ts: diff, threshold: entry.deleteThreshold };
  });

  // For each timestamp, if the difference between the current time and the timestamp is over 30 seconds, delete the entry
  for (let i = 0; i < times.length; i++) {
    if (times[i].ts > times[i].threshold) {
      console.log(
        `Above threshold of ${times[i].threshold} seconds - should delete ${times[i].id}`
      );
      handleAutoDelete(times[i].id, times[i].threshold);
    }
  }

  // Page code
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {tableName === "bad_actors"
          ? "Add New Person of Interest"
          : "Add New Incident"}
        <Link
          to={tableName === "bad_actors" ? "/bad_actors/new" : "/incidents/new"}
          className="link"
        >
          Add New
        </Link>
      </div>
      {/* Changed columns to use either userColumns or incidentColumns */}
      {/* 9/4/2023 - added download section */}
      {clientName !== "" ? (
        <DataGrid
          className="datagrid"
          rows={data}
          columns={
            tableName === "bad_actors"
              ? badActorColumns.concat(actionColumn)
              : incidentColumns.concat(actionColumn)
          }
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          disableRowSelectionOnClick
          components={{
            Toolbar: GridToolbar,
            // BaseCheckbox: customCheckbox
          }}
        />
      ) : (
        <h1>Nothing to see here!</h1>
      )}

      {open === true && (
        <DeleteModal
          deleteEntry={() => handleDelete(localStorage.getItem("deleteId"))}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default Datatable;
