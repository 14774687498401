// File created on 8/28/2023

// 8/22/2023 - list of admin IDs for the Exxon, Mobil, and Shell DBs respectively
// 8/23/2023 - Admins have full read and write access to the DB
// Users granted permission will only have read access
// All other users are denied access.

// This will be updated 
// Also added the super admin's ID
// Must also add their emails to the To field of the email template in Email JS
export const pne001AdminList = ["q4kUYddpDwNOUM1kyoJKnzEeDlS2", "bcZaK415xNW7hmRe81zV8zrpOoq2"] // vhoang61, fortiorguard
export const pnm001AdminList = ["cRo3CXEyHBfQBuXPuJX6ffdu1FY2", "bcZaK415xNW7hmRe81zV8zrpOoq2"] // namong42017, fortiorguard
export const pns001AdminList = ["5ngkzT0sDae1XAhJxRY8fSurUgq1", "bcZaK415xNW7hmRe81zV8zrpOoq2"] // namong22017, fortiorguard

// 8/23/2023
// List of users who only have read access to the DB
// These users are allowed to log in and view the DB, but they cannot add, update, or remove entries.
export const pne001ReadOnlyList = ["cRo3CXEyHBfQBuXPuJX6ffdu1FY2"]
export const pnm001ReadOnlyList = [""]
export const pns001ReadOnlyList = [""]