// 8/30/2023

export const chart_data = [
  {
    PN_E001: 76,
    PN_S001: 58,
    PN_M001: 75,
    date: "January 5, 2022",
  },
  {
    PN_E001: 62,
    PN_S001: 100,
    PN_M001: 65,
    date: "January 14, 2022",
  },
  {
    PN_E001: 48,
    PN_S001: 80,
    PN_M001: 67,
    date: "January 8, 2022",
  },
  {
    PN_E001: 3,
    PN_S001: 44,
    PN_M001: 4,
    date: "January 25, 2022",
  },
  {
    PN_E001: 5,
    PN_S001: 17,
    PN_M001: 64,
    date: "January 11, 2022",
  },
  {
    PN_E001: 38,
    PN_S001: 49,
    PN_M001: 45,
    date: "January 18, 2022",
  },
  {
    PN_E001: 83,
    PN_S001: 19,
    PN_M001: 70,
    date: "January 18, 2022",
  },
  {
    PN_E001: 69,
    PN_S001: 100,
    PN_M001: 79,
    date: "January 5, 2022",
  },
  {
    PN_E001: 10,
    PN_S001: 95,
    PN_M001: 99,
    date: "January 17, 2022",
  },
  {
    PN_E001: 10,
    PN_S001: 79,
    PN_M001: 100,
    date: "February 18, 2022",
  },
  {
    PN_E001: 46,
    PN_S001: 61,
    PN_M001: 81,
    date: "February 20, 2022",
  },
  {
    PN_E001: 71,
    PN_S001: 2,
    PN_M001: 61,
    date: "February 7, 2022",
  },
  {
    PN_E001: 67,
    PN_S001: 22,
    PN_M001: 10,
    date: "February 5, 2022",
  },
  {
    PN_E001: 88,
    PN_S001: 67,
    PN_M001: 37,
    date: "February 19, 2022",
  },
  {
    PN_E001: 96,
    PN_S001: 49,
    PN_M001: 98,
    date: "February 17, 2022",
  },
  {
    PN_E001: 48,
    PN_S001: 19,
    PN_M001: 68,
    date: "March 9, 2022",
  },
  {
    PN_E001: 38,
    PN_S001: 99,
    PN_M001: 39,
    date: "March 15, 2022",
  },
  {
    PN_E001: 29,
    PN_S001: 16,
    PN_M001: 76,
    date: "March 15, 2022",
  },
  {
    PN_E001: 35,
    PN_S001: 94,
    PN_M001: 12,
    date: "April 24, 2022",
  },
  {
    PN_E001: 78,
    PN_S001: 54,
    PN_M001: 35,
    date: "April 5, 2022",
  },
  {
    PN_E001: 74,
    PN_S001: 35,
    PN_M001: 64,
    date: "April 8, 2022",
  },
  {
    PN_E001: 26,
    PN_S001: 69,
    PN_M001: 50,
    date: "April 21, 2022",
  },
  {
    PN_E001: 37,
    PN_S001: 28,
    PN_M001: 12,
    date: "April 8, 2022",
  },
  {
    PN_E001: 48,
    PN_S001: 22,
    PN_M001: 74,
    date: "April 13, 2022",
  },
  {
    PN_E001: 50,
    PN_S001: 46,
    PN_M001: 57,
    date: "April 21, 2022",
  },
  {
    PN_E001: 84,
    PN_S001: 89,
    PN_M001: 67,
    date: "April 19, 2022",
  },
  {
    PN_E001: 59,
    PN_S001: 49,
    PN_M001: 33,
    date: "May 8, 2022",
  },
  {
    PN_E001: 17,
    PN_S001: 72,
    PN_M001: 69,
    date: "May 26, 2022",
  },
  {
    PN_E001: 1,
    PN_S001: 21,
    PN_M001: 78,
    date: "May 10, 2022",
  },
  {
    PN_E001: 14,
    PN_S001: 88,
    PN_M001: 78,
    date: "June 4, 2022",
  },
  {
    PN_E001: 86,
    PN_S001: 85,
    PN_M001: 19,
    date: "June 23, 2022",
  },
  {
    PN_E001: 8,
    PN_S001: 57,
    PN_M001: 60,
    date: "June 14, 2022",
  },
  {
    PN_E001: 48,
    PN_S001: 89,
    PN_M001: 86,
    date: "June 8, 2022",
  },
  {
    PN_E001: 31,
    PN_S001: 14,
    PN_M001: 77,
    date: "June 8, 2022",
  },
  {
    PN_E001: 2,
    PN_S001: 38,
    PN_M001: 33,
    date: "June 7, 2022",
  },
  {
    PN_E001: 22,
    PN_S001: 22,
    PN_M001: 100,
    date: "June 22, 2022",
  },
  {
    PN_E001: 65,
    PN_S001: 81,
    PN_M001: 68,
    date: "June 6, 2022",
  },
  {
    PN_E001: 97,
    PN_S001: 87,
    PN_M001: 82,
    date: "July 12, 2022",
  },
  {
    PN_E001: 59,
    PN_S001: 79,
    PN_M001: 100,
    date: "July 5, 2022",
  },
  {
    PN_E001: 59,
    PN_S001: 1,
    PN_M001: 13,
    date: "July 17, 2022",
  },
  {
    PN_E001: 28,
    PN_S001: 67,
    PN_M001: 20,
    date: "July 16, 2022",
  },
  {
    PN_E001: 61,
    PN_S001: 53,
    PN_M001: 44,
    date: "July 9, 2022",
  },
  {
    PN_E001: 81,
    PN_S001: 57,
    PN_M001: 35,
    date: "July 13, 2022",
  },
  {
    PN_E001: 44,
    PN_S001: 99,
    PN_M001: 42,
    date: "July 24, 2022",
  },
  {
    PN_E001: 90,
    PN_S001: 51,
    PN_M001: 38,
    date: "July 15, 2022",
  },
  {
    PN_E001: 79,
    PN_S001: 85,
    PN_M001: 58,
    date: "July 17, 2022",
  },
  {
    PN_E001: 15,
    PN_S001: 5,
    PN_M001: 79,
    date: "July 11, 2022",
  },
  {
    PN_E001: 5,
    PN_S001: 21,
    PN_M001: 91,
    date: "August 14, 2022",
  },
  {
    PN_E001: 14,
    PN_S001: 76,
    PN_M001: 94,
    date: "August 10, 2022",
  },
  {
    PN_E001: 17,
    PN_S001: 17,
    PN_M001: 74,
    date: "August 20, 2022",
  },
  {
    PN_E001: 78,
    PN_S001: 33,
    PN_M001: 86,
    date: "August 11, 2022",
  },
  {
    PN_E001: 18,
    PN_S001: 30,
    PN_M001: 21,
    date: "August 27, 2022",
  },
  {
    PN_E001: 47,
    PN_S001: 3,
    PN_M001: 58,
    date: "August 26, 2022",
  },
  {
    PN_E001: 25,
    PN_S001: 30,
    PN_M001: 73,
    date: "August 19, 2022",
  },
  {
    PN_E001: 47,
    PN_S001: 15,
    PN_M001: 44,
    date: "September 24, 2022",
  },
  {
    PN_E001: 71,
    PN_S001: 5,
    PN_M001: 39,
    date: "September 12, 2022",
  },
  {
    PN_E001: 23,
    PN_S001: 47,
    PN_M001: 56,
    date: "September 14, 2022",
  },
  {
    PN_E001: 28,
    PN_S001: 96,
    PN_M001: 69,
    date: "September 24, 2022",
  },
  {
    PN_E001: 32,
    PN_S001: 96,
    PN_M001: 9,
    date: "September 21, 2022",
  },
  {
    PN_E001: 76,
    PN_S001: 53,
    PN_M001: 2,
    date: "September 28, 2022",
  },
  {
    PN_E001: 71,
    PN_S001: 75,
    PN_M001: 76,
    date: "September 2, 2022",
  },
  {
    PN_E001: 59,
    PN_S001: 21,
    PN_M001: 37,
    date: "October 28, 2022",
  },
  {
    PN_E001: 91,
    PN_S001: 57,
    PN_M001: 53,
    date: "October 19, 2022",
  },
  {
    PN_E001: 95,
    PN_S001: 43,
    PN_M001: 35,
    date: "October 6, 2022",
  },
  {
    PN_E001: 24,
    PN_S001: 19,
    PN_M001: 38,
    date: "October 5, 2022",
  },
  {
    PN_E001: 28,
    PN_S001: 10,
    PN_M001: 72,
    date: "October 1, 2022",
  },
  {
    PN_E001: 83,
    PN_S001: 5,
    PN_M001: 77,
    date: "October 19, 2022",
  },
  {
    PN_E001: 100,
    PN_S001: 2,
    PN_M001: 33,
    date: "October 18, 2022",
  },
  {
    PN_E001: 12,
    PN_S001: 9,
    PN_M001: 86,
    date: "November 1, 2022",
  },
  {
    PN_E001: 32,
    PN_S001: 21,
    PN_M001: 89,
    date: "November 23, 2022",
  },
  {
    PN_E001: 52,
    PN_S001: 56,
    PN_M001: 20,
    date: "November 25, 2022",
  },
  {
    PN_E001: 56,
    PN_S001: 98,
    PN_M001: 4,
    date: "November 19, 2022",
  },
  {
    PN_E001: 76,
    PN_S001: 42,
    PN_M001: 55,
    date: "November 24, 2022",
  },
  {
    PN_E001: 13,
    PN_S001: 23,
    PN_M001: 52,
    date: "November 24, 2022",
  },
  {
    PN_E001: 97,
    PN_S001: 63,
    PN_M001: 61,
    date: "December 14, 2022",
  },
  {
    PN_E001: 24,
    PN_S001: 20,
    PN_M001: 54,
    date: "December 20, 2022",
  },
];

export const chart_data_6m_monthly = [
  { date: "2022-05", PN_E001: "131" },
  { date: "2022-06", PN_E001: "125" },
  { date: "2022-07", PN_E001: "118" },
  { date: "2022-08", PN_E001: "120" },
  { date: "2022-09", PN_E001: "123" },
  { date: "2022-10", PN_E001: "125" },
];

// export const chart_data_oct = [
//   { date: "2023-10-18", PN_E001: "0" },
//   { date: "2023-10-19", PN_E001: "5" },
//   { date: "2023-10-20", PN_E001: "8" },
//   { date: "2023-10-21", PN_E001: "6" },
//   { date: "2023-10-22", PN_E001: "9" },
//   { date: "2023-10-23", PN_E001: "10" },
// ];

export const chart_data_oct = [
  { date: "2023-08-18", PN_E001: "15" },
  { date: "2023-08-19", PN_E001: "14" },
  { date: "2023-08-20", PN_E001: "13" },
  { date: "2023-08-21", PN_E001: "12" },
  { date: "2023-08-22", PN_E001: "11" },
  { date: "2023-08-23", PN_E001: "12" },
  { date: "2023-08-24", PN_E001: "13" },
  { date: "2023-08-25", PN_E001: "14" },
  { date: "2023-08-26", PN_E001: "13" },
  { date: "2023-08-27", PN_E001: "13" },
  { date: "2023-08-28", PN_E001: "13" },
  { date: "2023-08-29", PN_E001: "14" },
  { date: "2023-08-30", PN_E001: "15" },
  { date: "2023-08-31", PN_E001: "16" },
  { date: "2023-09-01", PN_E001: "15" },
  { date: "2023-09-02", PN_E001: "14" },
  { date: "2023-09-03", PN_E001: "13" },
  { date: "2023-09-04", PN_E001: "12" },
  { date: "2023-09-05", PN_E001: "11" },
  { date: "2023-09-06", PN_E001: "11" },
  { date: "2023-09-07", PN_E001: "12" },
  { date: "2023-09-08", PN_E001: "13" },
  { date: "2023-09-09", PN_E001: "12" },
  { date: "2023-09-10", PN_E001: "12" },
  { date: "2023-09-11", PN_E001: "12" },
  { date: "2023-09-12", PN_E001: "13" },
  { date: "2023-09-13", PN_E001: "13" },
  { date: "2023-09-14", PN_E001: "12" },
  { date: "2023-09-15", PN_E001: "11" },
  { date: "2023-09-16", PN_E001: "10" },
  { date: "2023-09-17", PN_E001: "9" },
  { date: "2023-09-18", PN_E001: "9" },
  { date: "2023-09-19", PN_E001: "10" },
  { date: "2023-09-20", PN_E001: "9" },
  { date: "2023-09-21", PN_E001: "8" },
  { date: "2023-09-22", PN_E001: "9" },
  { date: "2023-09-23", PN_E001: "10" },
  { date: "2023-09-24", PN_E001: "11" },
  { date: "2023-09-25", PN_E001: "10" },
  { date: "2023-09-26", PN_E001: "11" },
  { date: "2023-09-27", PN_E001: "10" },
  { date: "2023-09-28", PN_E001: "11" },
  { date: "2023-09-29", PN_E001: "12" },
  { date: "2023-09-30", PN_E001: "13" },
  { date: "2023-10-01", PN_E001: "13" },
  { date: "2023-10-02", PN_E001: "12" },
  { date: "2023-10-03", PN_E001: "11" },
  { date: "2023-10-04", PN_E001: "10" },
  { date: "2023-10-05", PN_E001: "9" },
  { date: "2023-10-06", PN_E001: "10" },
  { date: "2023-10-07", PN_E001: "9" },
  { date: "2023-10-08", PN_E001: "10" },
  { date: "2023-10-09", PN_E001: "10" },
  { date: "2023-10-10", PN_E001: "10" },
  { date: "2023-10-11", PN_E001: "11" },
  { date: "2023-10-12", PN_E001: "10" },
  { date: "2023-10-13", PN_E001: "10" },
  { date: "2023-10-14", PN_E001: "9" },
  { date: "2023-10-15", PN_E001: "10" },
  { date: "2023-10-16", PN_E001: "10" },
  { date: "2023-10-17", PN_E001: "10" },
  { date: "2023-10-18", PN_E001: "10" },
  { date: "2023-10-19", PN_E001: "9" },
  { date: "2023-10-20", PN_E001: "9" },
  { date: "2023-10-21", PN_E001: "9" },
  { date: "2023-10-22", PN_E001: "10" },
  { date: "2023-10-23", PN_E001: "11" },
];

export const chart_data_6m = [
  { date: "2022-01-02", PN_E001: "100" },
  { date: "2022-01-03", PN_E001: "102" },
  { date: "2022-01-04", PN_E001: "98" },
  { date: "2022-01-05", PN_E001: "98" },
  { date: "2022-01-06", PN_E001: "94" },
  { date: "2022-01-07", PN_E001: "98" },
  { date: "2022-01-08", PN_E001: "98" },
  { date: "2022-01-09", PN_E001: "93" },
  { date: "2022-01-10", PN_E001: "90" },
  { date: "2022-01-11", PN_E001: "94" },
  { date: "2022-01-12", PN_E001: "89" },
  { date: "2022-01-13", PN_E001: "86" },
  { date: "2022-01-14", PN_E001: "91" },
  { date: "2022-01-15", PN_E001: "95" },
  { date: "2022-01-16", PN_E001: "92" },
  { date: "2022-01-17", PN_E001: "90" },
  { date: "2022-01-18", PN_E001: "88" },
  { date: "2022-01-19", PN_E001: "87" },
  { date: "2022-01-20", PN_E001: "84" },
  { date: "2022-01-21", PN_E001: "89" },
  { date: "2022-01-22", PN_E001: "93" },
  { date: "2022-01-23", PN_E001: "96" },
  { date: "2022-01-24", PN_E001: "98" },
  { date: "2022-01-25", PN_E001: "99" },
  { date: "2022-01-26", PN_E001: "98" },
  { date: "2022-01-27", PN_E001: "102" },
  { date: "2022-01-28", PN_E001: "104" },
  { date: "2022-01-29", PN_E001: "108" },
  { date: "2022-01-30", PN_E001: "110" },
  { date: "2022-01-31", PN_E001: "110" },
  { date: "2022-02-01", PN_E001: "115" },
  { date: "2022-02-02", PN_E001: "116" },
  { date: "2022-02-03", PN_E001: "118" },
  { date: "2022-02-04", PN_E001: "123" },
  { date: "2022-02-05", PN_E001: "126" },
  { date: "2022-02-06", PN_E001: "124" },
  { date: "2022-02-07", PN_E001: "123" },
  { date: "2022-02-08", PN_E001: "127" },
  { date: "2022-02-09", PN_E001: "128" },
  { date: "2022-02-10", PN_E001: "128" },
  { date: "2022-02-11", PN_E001: "126" },
  { date: "2022-02-12", PN_E001: "127" },
  { date: "2022-02-13", PN_E001: "131" },
  { date: "2022-02-14", PN_E001: "133" },
  { date: "2022-02-15", PN_E001: "130" },
  { date: "2022-02-16", PN_E001: "128" },
  { date: "2022-02-17", PN_E001: "124" },
  { date: "2022-02-18", PN_E001: "124" },
  { date: "2022-02-19", PN_E001: "123" },
  { date: "2022-02-20", PN_E001: "118" },
  { date: "2022-02-21", PN_E001: "115" },
  { date: "2022-02-22", PN_E001: "117" },
  { date: "2022-02-23", PN_E001: "114" },
  { date: "2022-02-24", PN_E001: "118" },
  { date: "2022-02-25", PN_E001: "119" },
  { date: "2022-02-26", PN_E001: "123" },
  { date: "2022-02-27", PN_E001: "123" },
  { date: "2022-02-28", PN_E001: "125" },
  { date: "2022-03-01", PN_E001: "123" },
  { date: "2022-03-02", PN_E001: "123" },
  { date: "2022-03-03", PN_E001: "122" },
  { date: "2022-03-04", PN_E001: "121" },
  { date: "2022-03-05", PN_E001: "118" },
  { date: "2022-03-06", PN_E001: "121" },
  { date: "2022-03-07", PN_E001: "121" },
  { date: "2022-03-08", PN_E001: "120" },
  { date: "2022-03-09", PN_E001: "121" },
  { date: "2022-03-10", PN_E001: "118" },
  { date: "2022-03-11", PN_E001: "114" },
  { date: "2022-03-12", PN_E001: "117" },
  { date: "2022-03-13", PN_E001: "117" },
  { date: "2022-03-14", PN_E001: "117" },
  { date: "2022-03-15", PN_E001: "119" },
  { date: "2022-03-16", PN_E001: "120" },
  { date: "2022-03-17", PN_E001: "124" },
  { date: "2022-03-18", PN_E001: "126" },
  { date: "2022-03-19", PN_E001: "130" },
  { date: "2022-03-20", PN_E001: "127" },
  { date: "2022-03-21", PN_E001: "132" },
  { date: "2022-03-22", PN_E001: "127" },
  { date: "2022-03-23", PN_E001: "130" },
  { date: "2022-03-24", PN_E001: "126" },
  { date: "2022-03-25", PN_E001: "125" },
  { date: "2022-03-26", PN_E001: "123" },
  { date: "2022-03-27", PN_E001: "128" },
  { date: "2022-03-28", PN_E001: "130" },
  { date: "2022-03-29", PN_E001: "131" },
  { date: "2022-03-30", PN_E001: "126" },
  { date: "2022-03-31", PN_E001: "128" },
  { date: "2022-04-01", PN_E001: "128" },
  { date: "2022-04-02", PN_E001: "126" },
  { date: "2022-04-03", PN_E001: "128" },
  { date: "2022-04-04", PN_E001: "123" },
  { date: "2022-04-05", PN_E001: "126" },
  { date: "2022-04-06", PN_E001: "130" },
  { date: "2022-04-07", PN_E001: "131" },
  { date: "2022-04-08", PN_E001: "136" },
  { date: "2022-04-09", PN_E001: "138" },
  { date: "2022-04-10", PN_E001: "135" },
  { date: "2022-04-11", PN_E001: "140" },
  { date: "2022-04-12", PN_E001: "141" },
  { date: "2022-04-13", PN_E001: "138" },
  { date: "2022-04-14", PN_E001: "139" },
  { date: "2022-04-15", PN_E001: "138" },
  { date: "2022-04-16", PN_E001: "137" },
  { date: "2022-04-17", PN_E001: "142" },
  { date: "2022-04-18", PN_E001: "139" },
  { date: "2022-04-19", PN_E001: "143" },
  { date: "2022-04-20", PN_E001: "148" },
  { date: "2022-04-21", PN_E001: "145" },
  { date: "2022-04-22", PN_E001: "141" },
  { date: "2022-04-23", PN_E001: "137" },
  { date: "2022-04-24", PN_E001: "133" },
  { date: "2022-04-25", PN_E001: "129" },
  { date: "2022-04-26", PN_E001: "131" },
  { date: "2022-04-27", PN_E001: "127" },
  { date: "2022-04-28", PN_E001: "122" },
  { date: "2022-04-29", PN_E001: "118" },
  { date: "2022-04-30", PN_E001: "116" },
  { date: "2022-05-01", PN_E001: "112" },
  { date: "2022-05-02", PN_E001: "112" },
  { date: "2022-05-03", PN_E001: "109" },
  { date: "2022-05-04", PN_E001: "108" },
  { date: "2022-05-05", PN_E001: "109" },
  { date: "2022-05-06", PN_E001: "111" },
  { date: "2022-05-07", PN_E001: "113" },
  { date: "2022-05-08", PN_E001: "112" },
  { date: "2022-05-09", PN_E001: "109" },
  { date: "2022-05-10", PN_E001: "113" },
  { date: "2022-05-11", PN_E001: "118" },
  { date: "2022-05-12", PN_E001: "117" },
  { date: "2022-05-13", PN_E001: "122" },
  { date: "2022-05-14", PN_E001: "125" },
  { date: "2022-05-15", PN_E001: "125" },
  { date: "2022-05-16", PN_E001: "120" },
  { date: "2022-05-17", PN_E001: "119" },
  { date: "2022-05-18", PN_E001: "114" },
  { date: "2022-05-19", PN_E001: "110" },
  { date: "2022-05-20", PN_E001: "106" },
  { date: "2022-05-21", PN_E001: "111" },
  { date: "2022-05-22", PN_E001: "108" },
  { date: "2022-05-23", PN_E001: "105" },
  { date: "2022-05-24", PN_E001: "110" },
  { date: "2022-05-25", PN_E001: "107" },
  { date: "2022-05-26", PN_E001: "106" },
  { date: "2022-05-27", PN_E001: "106" },
  { date: "2022-05-28", PN_E001: "102" },
  { date: "2022-05-29", PN_E001: "101" },
  { date: "2022-05-30", PN_E001: "100" },
  { date: "2022-05-31", PN_E001: "101" },
  { date: "2022-06-01", PN_E001: "98" },
  { date: "2022-06-02", PN_E001: "97" },
  { date: "2022-06-03", PN_E001: "98" },
  { date: "2022-06-04", PN_E001: "100" },
  { date: "2022-06-05", PN_E001: "99" },
  { date: "2022-06-06", PN_E001: "104" },
  { date: "2022-06-07", PN_E001: "100" },
  { date: "2022-06-08", PN_E001: "101" },
  { date: "2022-06-09", PN_E001: "101" },
  { date: "2022-06-10", PN_E001: "96" },
  { date: "2022-06-11", PN_E001: "96" },
  { date: "2022-06-12", PN_E001: "95" },
  { date: "2022-06-13", PN_E001: "93" },
  { date: "2022-06-14", PN_E001: "94" },
  { date: "2022-06-15", PN_E001: "93" },
  { date: "2022-06-16", PN_E001: "92" },
  { date: "2022-06-17", PN_E001: "88" },
  { date: "2022-06-18", PN_E001: "89" },
  { date: "2022-06-19", PN_E001: "90" },
  { date: "2022-06-20", PN_E001: "85" },
  { date: "2022-06-21", PN_E001: "84" },
  { date: "2022-06-22", PN_E001: "81" },
  { date: "2022-06-23", PN_E001: "79" },
  { date: "2022-06-24", PN_E001: "82" },
  { date: "2022-06-25", PN_E001: "77" },
  { date: "2022-06-26", PN_E001: "81" },
  { date: "2022-06-27", PN_E001: "78" },
  { date: "2022-06-28", PN_E001: "82" },
  { date: "2022-06-29", PN_E001: "83" },
  { date: "2022-06-30", PN_E001: "79" },
];
