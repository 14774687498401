import "./navbar.scss";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";

//8/29/2023 - commented out every icon on the navbar except for the dark mode icon. replaced the search bar with an oil barrel icon.

// 7/24/2023 -> changed avatar
import avatar from "../../assets/oil_tank.jpg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search"></div>
        <div className="items">
          <div className="item"></div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            {/* <FullscreenExitOutlinedIcon className="icon" /> */}
          </div>
          <div className="item">
            {/* <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div> */}
          </div>
          <div className="item">
            {/* <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div> */}
          </div>
          <div className="item">
            {/* <ListOutlinedIcon className="icon" /> */}
          </div>
          <div className="item">
            {/* 8/29/2023 - clicking the avatar now redirects to the front page. */}
            <Link to="/">
              <img src={avatar} alt="" className="avatar" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
