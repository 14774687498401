import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import SlideshowIcon from "@mui/icons-material/Slideshow"; // 8/31/2023 - use as video icon for video uploading form field
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"; // 8/25/2023 - for multi image uploading
import { useEffect, useState } from "react";

// 9/3/2023
import AiWidget from "../../components/aiwidget/AiWidget";

import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
// 7/13/2023 and 7/14/2023
// 7/27/2023 - imported bad actor table name
// 8/22/2023 - added imports for auth and admin lists
import {
  //auth,
  db,
  storage,
  badActorTableName,
  incidentsTableName,
  sendEmail,
} from "../../firebase";

//7/14/2023
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";

// 8/28/2023 - import the send notification function
import { sendNotification } from "../../firebase";

// 7/15/2023 - fixed bug where clicking the Send button when no fields are entered would do nothing

// 8/28/2023
import {
  pne001AdminList,
  pnm001AdminList,
  pns001AdminList,
  // pne001ReadOnlyList,
  // pnm001ReadOnlyList,
  // pns001ReadOnlyList
} from "../../adminlist";

// 9/3/2023
import MenuIcon from "@mui/icons-material/Menu";

const New = ({ inputs, title }) => {
  //console.log(auth.currentUser.uid)

  // 8/17/2023 - added call to local storage
  const clientName = localStorage.getItem("clientName");

  // UseState for getting and setting the uploaded filename
  const [file, setFile] = useState("");

  // 8/31/2023 get and set a video (.mp4)
  const [videoFile, setVideoFile] = useState("");

  // 8/25/2023 - for uploading multiple files.
  const [multiFiles, setMultiFiles] = useState([]);

  const [data, setData] = useState({});

  const [badActorData, setBadActorData] = useState([]);

  // 8/20/2023
  const [badActorId, setBadActorId] = useState("");

  // New useState to prevent accidental uploads
  const [per, setPerc] = useState(null);

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const [hasRWAccess, setHasRWAccess] = useState(true);

  const currentUserUID = JSON.parse(localStorage.getItem("user")).uid; // auth?.currentUser?.uid; - fixed a bug on 8/25/2023 where auth.currentUser may sometimes be null

  console.log(currentUserUID);

  // // Trigger push notifications when the user adds a bad actor
  // const sendNotification = (title, body) => {

  //     const message = {
  //       notification: {
  //         title: title,
  //         body: body
  //       },
  //     };

  //     const notificationOptions = {
  //         body: message.notification.body
  //     };

  //     let notification = new Notification(message.notification.title, notificationOptions)

  //     notification.onclick = (e) => {
  //       e.preventDefault();
  //       notification.close();
  //     }

  // }

  // Disable buttons if the current user is not in the current list of admins
  useEffect(() => {
    // If the current user is not in the list of admins for that particular DB, then disable buttons and show a warning message
    if (
      (clientName === "PN-E001" && !pne001AdminList.includes(currentUserUID)) ||
      (clientName === "PN-M001" && !pnm001AdminList.includes(currentUserUID)) ||
      (clientName === "PN-S001" && !pns001AdminList.includes(currentUserUID))
    ) {
      setHasRWAccess(false);
    }
  }, []);

  // Used for single file uploads
  useEffect(() => {
    const uploadFile = () => {
      //const name = new Date().getTime() + file.name; // prevent overwriting of files with the same name by appending it to the unix timestamp

      //console.log(name);

      // 8/25/2023 - created variable to store the full filepath as "clientName/file.name" so it ends up in a folder
      const fullFilePath = clientName + "/" + file.name;

      // Reference to the file
      // 8/25/2023 - changed to take the full file path
      //const storageRef = ref(storage, file.name);
      const storageRef = ref(storage, fullFilePath);
      // Begin the upload task
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          // take the url and set the data again
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // set the URL in the database
            // use the function method (prev) => ({...prev...}) instead of {...data} because of a warning that will occur
            // ...prev means use all the previous data
            // React Hook useEffect has a missing dependency: 'data'. Either include it or remove the dependency array. You can also do a functional update 'setData(d => ...)' if you only need 'data' in the 'setData' call.
            setData((prev) => ({ ...prev, img: downloadURL }));
            //setData(...data)
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  // useEffect for uploading multiple files
  useEffect(() => {
    let fileURLs = [];

    // Helper Function for uploading multiple files
    const uploadMultiFile = () => {
      console.log(multiFiles);

      console.log(multiFiles.length !== 0);
      console.log("multi files > 0?");
      console.log(multiFiles.length > 0);

      // If at least 1 file was uploaded, upload each image one at a time
      if (multiFiles.length > 0) {
        for (var i = 0; i < multiFiles.length; i++) {
          let imageFile = multiFiles[i];
          uploadSingleFile(imageFile);
        }
      }

      console.log("is fileURLs filled?");
      console.log(fileURLs);

      // Add the list of fileURLs to the bad actor
      // use the function method (prev) => ({...prev...}) instead of {...data} because of a warning that will occur
      // ...prev means use all the previous data
      // React Hook useEffect has a missing dependency: 'data'. Either include it or remove the dependency array. You can also do a functional update 'setData(d => ...)' if you only need 'data' in the 'setData' call.
      setData((prev) => ({ ...prev, moreImgs: fileURLs }));
    };

    // Function to upload a single file and be called by the above multiFiles function
    const uploadSingleFile = (singleFile) => {
      // 8/25/2023 - created variable to store the full filepath as "clientName/file.name" so it ends up in a folder
      const fullFilePath = clientName + "/" + singleFile.name;

      // Reference to the file
      // 8/25/2023 - changed to take the full file path
      //const storageRef = ref(storage, file.name);
      const storageRef = ref(storage, fullFilePath);
      // Begin the upload task
      const uploadTask = uploadBytesResumable(storageRef, singleFile);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        // Stage 1: Uploading
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        // Result 1: Error
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        // Result 2: Successful upload
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          // take the url and set the data again
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // Update the list of fileURLs

            fileURLs.push(downloadURL);
          });
        }
      );
    };

    // To upload, at least one file must be selected
    multiFiles.length !== 0 && uploadMultiFile();
  }, [multiFiles]);

  // Second useEffect for downloading bad actor data
  useEffect(() => {
    // Obtain the bad actor table
    const fullTableName = "Clients/" + clientName + "/bad_actors";
    console.log(fullTableName);

    const unsub = onSnapshot(
      collection(db, fullTableName),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          console.log(doc);
          // 8/13/2023 - updated code so that the data for an individual bad actor is stored in a variable, then the timestamp is updated
          // After this, add the bad actor data to the list
          let doc_data = { id: doc.id, ...doc.data() };
          list.push(doc_data);
          //console.log(list)
        });
        setBadActorData(list);
      },
      (error) => {
        setError(true);
        console.log("An error has occurred");
        console.log(error);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      unsub();
    };
  }, []);

  // 8/31/2023 - Used for video uploads
  useEffect(() => {
    const uploadVideoFile = () => {
      // 8/25/2023 - created variable to store the full filepath as "clientName/file.name" so it ends up in a folder
      const fullFilePath = clientName + "/" + videoFile.name;

      // Reference to the file
      // 8/25/2023 - changed to take the full file path
      //const storageRef = ref(storage, file.name);
      const storageRef = ref(storage, fullFilePath);
      // Begin the upload task
      const uploadTask = uploadBytesResumable(storageRef, videoFile);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          // take the url and set the data again
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // set the URL in the database
            // use the function method (prev) => ({...prev...}) instead of {...data} because of a warning that will occur
            // ...prev means use all the previous data
            // React Hook useEffect has a missing dependency: 'data'. Either include it or remove the dependency array. You can also do a functional update 'setData(d => ...)' if you only need 'data' in the 'setData' call.
            setData((prev) => ({ ...prev, video: downloadURL }));
            //setData(...data)
          });
        }
      );
    };
    videoFile && uploadVideoFile();
  }, [videoFile]);

  // Handling the input
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  // 8/20/2023 - call this function each time the user selects a different bad actor for the incident
  const handleIDChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setBadActorId(value); // change the bad actor's ID
    setData({ ...data, [id]: value });
  };

  // 9/12/2023 - call this function
  const handleSDTSChange = (e) => {
    const deleteThreshold = e.target.id;
    const value = e.target.value;
    setData({ ...data, [deleteThreshold]: value });
  };

  console.log(data);

  // 7/13/2023
  // Create a new user with email and password if there is one

  // 7/25/2023
  // Bad actors don't need emails and passwords, so we commented out the const res line
  // setDoc changed to addDoc since the user will have a random ID upon creation.
  const handleAdd = async (e) => {
    e.preventDefault();

    try {
      //const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      // Add a new document in collection "cities"
      // doc(db, collection name, document id)
      // If you want to add a collection with a random id, you must use addDoc instead of setDoc and you must use collection instead of doc
      // 7/25/2023 - changed to add data to bad_actors
      // Changed path -> bad_actors is now Clients/PN-S001/bad_actors
      // 7/27/2023 - if we are adding to the incidents table, change the table name
      let tableToAddTo = badActorTableName;
      if (title !== "Add New Bad Actor") {
        tableToAddTo = incidentsTableName;
      }

      // 8/17/2023 - defined a full table name and changed tableToAddTo to fullTableName
      const fullTableName = "Clients/" + clientName + "/" + tableToAddTo;
      console.log(fullTableName);
      await addDoc(collection(db, fullTableName), {
        ...data,
        //moreImgs: [], // 8/31/2023 - if the user doesn't add additional images, a blank array will be used in its place
        addedTimeStamp: serverTimestamp(),
        timeStamp: serverTimestamp(),
      });
      sendNotification(
        "Change in Database",
        `A change in the database has been made to ${fullTableName}`
      );
      sendEmail("template_xttum5n");
      // Send the user back to the previous page
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(badActorData);
  console.log(badActorId);

  // 9/2/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/3/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1200
  );
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200);
  console.log(isLargeDevice);

  // ========================================================================================

  // Mobile device useeffect (<= 768)
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Medium device useEffect
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Large device useEffect
  useEffect(() => {
    // Function to update state based on window size
    // Auto hide the sidebar as we already have one
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth > 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Create the HTML
  return (
    <div className="new">
      {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
      {isMobile || isMediumDevice ? (
        <div className="hamburger-icon" onClick={handleSidebar}>
          <MenuIcon />
        </div>
      ) : (
        // Must be a large device with a screen width > 1200px

        <Sidebar />
      )}

      {(isMobile || isMediumDevice) && isSidebarOpen && <Sidebar />}

      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          {(title === "Add New Bad Actor" || title === "Add New Incident") && (
            <div className="left">
              {!hasRWAccess && (
                <h1 style={{ color: "red" }}>
                  Sorry, you do not have permission to add to this database.
                </h1>
              )}
              <img
                src={
                  file
                    ? URL.createObjectURL(file)
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
              />
            </div>
          )}
          <div className="right">
            <form onSubmit={handleAdd} encType="multipart/form-data">
              {/* 7/27/2023 - Only show the image upload form if we are adding a bad actor or (8/20/2023) incident */}
              {(title === "Add New Bad Actor" ||
                title === "Add New Incident") && (
                <div className="formInput">
                  <label htmlFor="file">
                    Upload Profile Image:{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    disabled={!hasRWAccess}
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
              )}
              {/* Multi image uploading */}
              <div className="formInput">
                {/* 8/25/2023 - htmlFor property must be the same as the id in the input field or you will not be able to select multiple files with ctrl or shift click. */}
                {/* 9/4/2023 - changed Upload Many Images to Upload More Images */}
                <label htmlFor="multiFile">
                  Upload More Images: <LibraryAddIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="multiFile"
                  disabled={!hasRWAccess}
                  multiple
                  onChange={(e) => {
                    console.log("You selected " + e.target.files);
                    setMultiFiles(e.target.files);
                  }}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    disabled={!hasRWAccess}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}

              {/* 9/12/2023 Field for adding self destruct time (select 10 seconds or 3 months) after the create date*/}
              {title === "Add New Bad Actor" && (
                <div className="formInput" key="selfDestructTimeStamp">
                  <label>Auto-Destruct Time</label>
                  <select
                    id="deleteThreshold"
                    type="select"
                    disabled={!hasRWAccess}
                    onChange={handleSDTSChange}
                  >
                    <option key="default" value="Never">
                      Select Auto-Destruct Time
                    </option>
                    <option key="1" value={`${10}`}>
                      10 seconds
                    </option>
                    <option key="2" value={`${3 * 30 * 24 * 60 * 60}`}>
                      3 months
                    </option>
                  </select>
                </div>
              )}

              {/* 9/7/2023 Field for adding bad actor's history */}
              {title === "Add New Bad Actor" && (
                <div className="formInput" key="history">
                  <label>Offense history</label>
                  <textarea
                    id="history"
                    placeholder="Add Offense history"
                    onChange={handleInput}
                  ></textarea>
                </div>
              )}

              {/* 8/20/2023 - added a select dialogue for selecting the bad actor's name */}
              {title === "Add New Incident" && (
                <div className="formInput" key="selectBadActor">
                  <label>Bad Actor Name</label>
                  <select
                    id="badActorID"
                    type="select"
                    disabled={!hasRWAccess}
                    onChange={handleIDChange}
                  >
                    <option key="default" value="Select Bad Actor">
                      Select Bad Actor
                    </option>
                    {badActorData.map((badActor) => (
                      <option key={badActor.id} value={badActor.id}>
                        {badActor.displayName}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* 8/31/2023 - Upload videos */}
              {title === "Add New Incident" && (
                <div className="formInput">
                  <label htmlFor="videoFile">
                    Upload Incident Video: <SlideshowIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="videoFile"
                    disabled={!hasRWAccess}
                    onChange={(e) => setVideoFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
              )}

              {/* Disable the button until the image is finished uploading or if the user is not the admin of the DB */}
              {/* Changed classname to formInput so the button is aligned with the rest of the formInputs */}
              <div className="button">
                <button
                  disabled={(per !== null && per < 100) || !hasRWAccess}
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
          {/* End bottom div */}
        </div>
        <AiWidget />
      </div>
    </div>
  );
};

export default New;
