// Chart widget
// Originally created with fake data
// 9/16/2023 - added regression

import "./chart.scss";
import {
  //AreaChart,
  //Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  Legend,
  LineChart,
  Line,
  //Brush,
  Label,
} from "recharts";

// 8/30/2023 - synthetic data for the chart about incidents
import {
  //chart_data,
  chart_data_6m_monthly,
  chart_data_oct,
  //chart_data_6m_monthly,
} from "../../chartdata";

// 9/16/2023 - regression line
import regression from "regression";
//{ DataPoint }
//import { useTheme } from "@emotion/react";
import { useMemo, useState } from "react";
import { Button } from "@mui/material";

// 8/31/2023 - width was made a prop to resolve spacing issues with the chart widget on the bad actor/incident page
const Chart = ({ aspect, title, chart_width }) => {
  //const { palette } = useTheme();
  const [isPredictions, setIsPredictions] = useState(false);

  // // obtain formatted data
  // const formattedData = useMemo(() => {
  //   if (!chart_data_6m_daily) return [];

  //   console.log(chart_data_6m_daily);
  //   const formatted = chart_data_6m_daily.map(({ PN_E001 }, i) => {
  //     return [i, PN_E001];
  //   });
  //   console.log(formatted);
  //   const regressionLine_E = regression.polynomial(formatted, { order: 1 });
  //   // const regressionLine_S = regression.linear(formatted);
  //   // const regressionLine_M = regression.linear(formatted);

  //   return chart_data_6m_daily.map(({ date, PN_E001 }, i) => {
  //     return {
  //       date: date,
  //       // Actual incident count
  //       "Incidents (PN-E001)": PN_E001,
  //       // Best fit line based on the data distribution
  //       "Regression Line (PN-E001)": regressionLine_E.points[i][1],
  //       // Predicted number of incidents in the next 6 months
  //       "Predicted Incidents (PN-E001)": regressionLine_E.predict(i + 5)[1],
  //     };
  //   });
  // }, []);

  // predictedData = [];

  const formattedData = useMemo(() => {
    if (!chart_data_oct) return [];

    const formatted = chart_data_oct.map(({ PN_E001 }, i) => {
      return [i, parseFloat(PN_E001)];
    });

    //const regressionLine_E = regression.polynomial(formatted, { order: 2 });
    const regressionLine_E = regression.linear(formatted);

    const allData = chart_data_oct.map((item) => {
      if (item.date === "2023-10-23") {
        // Include the June data point in both datasets
        return {
          date: item.date,
          PN_E001: item.PN_E001,
          PN_E001_Predicted: item.PN_E001,
        };
      } else {
        return {
          date: item.date,
          PN_E001: item.PN_E001,
          PN_E001_Predicted: null,
        };
      }
    });
    const lastDataPoint = chart_data_oct[chart_data_oct.length - 1];
    console.log(lastDataPoint);
    const lastDate = new Date(lastDataPoint.date); // Assuming the format is YYYY-MM-DD

    lastDate.setDate(lastDate.getDate() + 1);
    console.log("Last date");
    console.log(lastDate);
    let nextDayIndex = chart_data_oct.length - 1;
    console.log(nextDayIndex);
    for (let i = 1; i <= 5; i++) {
      const nextDayDate = new Date(lastDate);
      nextDayDate.setDate(lastDate.getDate() + i); // Increment the date by i days

      console.log(chart_data_oct.length);
      //const nextDayIndex = chart_data_oct.length - 1 + i / 30; // Approximate day as 1/30 of a month
      nextDayIndex += 1;
      console.log(nextDayIndex);
      //console.log("Next day index");
      //console.log(nextDayIndex);
      const predictedValue = Math.round(
        regressionLine_E.predict(nextDayIndex)[1]
      );

      const dateString = `${nextDayDate.getFullYear()}-${String(
        nextDayDate.getMonth() + 1
      ).padStart(2, "0")}-${String(nextDayDate.getDate()).padStart(2, "0")}`;

      allData.push({
        date: dateString,
        PN_E001: null,
        PN_E001_Predicted: String(predictedValue.toFixed(2)),
      });
    }

    return allData;
  }, []);

  // const formattedData = useMemo(() => {
  //   if (!chart_data_6m_monthly) return [];

  //   const formatted = chart_data_6m_monthly.map(({ PN_E001 }, i) => {
  //     return [i, parseFloat(PN_E001)];
  //   });

  //   const regressionLine_E = regression.polynomial(formatted, { order: 1 });

  //   //const allData = [...chart_data_6m_monthly]; // Clone the original data\
  //   const allData = chart_data_6m_monthly.map((item) => {
  //     if (item.date === "2022-06") {
  //       // Include the June data point in both datasets
  //       return {
  //         date: item.date,
  //         PN_E001: item.PN_E001,
  //         PN_E001_Predicted: item.PN_E001,
  //       };
  //     } else {
  //       return {
  //         date: item.date,
  //         PN_E001: item.PN_E001,
  //         PN_E001_Predicted: null,
  //       };
  //     }
  //   });

  //   const lastDataPoint =
  //     chart_data_6m_monthly[chart_data_6m_monthly.length - 1];
  //   const lastDate = new Date(lastDataPoint.date + "-01"); // Assuming the format is YYYY-MM

  //   for (let i = 1; i <= 5; i++) {
  //     const nextDayDate = new Date(lastDate);
  //     nextDayDate.setDate(lastDate.getDate() + i); // Increment the date by i days

  //     const nextDayIndex = chart_data_6m_monthly.length - 1 + i / 30; // Approximate day as 1/30 of a month
  //     const predictedValue = regressionLine_E.predict(nextDayIndex)[1];

  //     const dateString = `${nextDayDate.getFullYear()}-${String(
  //       nextDayDate.getMonth() + 1
  //     ).padStart(2, "0")}-${String(nextDayDate.getDate()).padStart(2, "0")}`;

  //     allData.push({
  //       date: dateString,
  //       PN_E001: null,
  //       PN_E001_Predicted: String(predictedValue.toFixed(2)),
  //     });
  //   }

  //   return allData;
  // }, []);

  // const formattedData = useMemo(() => {
  //   if (!chart_data_6m_monthly) return [];

  //   const formatted = chart_data_6m_monthly.map(({ PN_E001 }, i) => {
  //     return [i, parseFloat(PN_E001)];
  //   });

  //   const regressionLine_E = regression.polynomial(formatted, { order: 1 });

  //   const allData = chart_data_6m_monthly.map((item) => {
  //     if (item.date === "2022-06") {
  //       // Include the June data point in both datasets
  //       return {
  //         date: item.date,
  //         PN_E001: item.PN_E001,
  //         PN_E001_Predicted: item.PN_E001,
  //       };
  //     } else {
  //       return {
  //         date: item.date,
  //         PN_E001: item.PN_E001,
  //         PN_E001_Predicted: null,
  //       };
  //     }
  //   });

  //   for (let i = 1; i <= 5; i++) {
  //     const nextMonthIndex = chart_data_6m_monthly.length - 1 + i;
  //     const predictedValue = regressionLine_E.predict(nextMonthIndex)[1];

  //     const year = 2022;
  //     const nextMonth = 6 + i;
  //     const dateString = `${year}-${String(nextMonth).padStart(2, "0")}`;

  //     allData.push({
  //       date: dateString,
  //       PN_E001: null,
  //       PN_E001_Predicted: String(predictedValue.toFixed(2)),
  //     });
  //   }

  //   return allData;
  // }, []);

  // // Predict the next 5 months
  // //const predictedData = [];
  // for (let i = 0; i < 5; i++) {
  //   const nextMonthIndex = chart_data_6m_monthly.length + i;
  //   const predictedValue = regressionLine_E.predict(nextMonthIndex)[1];

  //   const year = 2022;
  //   const nextMonth = 7 + i; // Starting from July
  //   const dateString = `${year}-${String(nextMonth).padStart(2, "0")}`;

  //   predictedData.push({
  //     date: dateString,
  //     PN_E001: String(predictedValue.toFixed(2)), // Convert back to string, rounded to 2 decimal places
  //   });
  // }

  //   return [...chart_data_6m_monthly, ...predictedData];
  // }, []);

  //const dataToDisplay = isPredictions ? formattedData : chart_data_oct;

  console.log(formattedData);
  return (
    <div className="chart">
      <div className="title">{title}</div>
      {/* 8/29/2023 - edited width to 99% */}
      <Button
        onClick={() => setIsPredictions(!isPredictions)}
        className="showPredictionsButton"
      >
        Show predictions
      </Button>
      <ResponsiveContainer width={chart_width} aspect={aspect}>
        <LineChart
          data={formattedData}
          margin={{
            top: 20,
            right: 75,
            left: 20,
            bottom: 80,
          }}
        >
          {/* 9/24/2023 - define the linear gradient */}
          <defs>
            <linearGradient id="pne001" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} /> */}
              <stop offset="5%" stopColor="#c1412d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#c1412d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke={"gray"} />
          <XAxis dataKey="date" tickLine={false} style={{ fontSize: "10px" }}>
            <Label value="Month" offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis
            domain={[0, 20]}
            axisLine={{ strokeWidth: "0" }}
            style={{ fontSize: "10px" }}
            //tickFormatter={(v) => `${v}`}
          >
            <Label
              value="Incidents"
              angle={-90}
              offset={-5}
              position="insideLeft"
            />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="top" />
          <Line
            type="monotone"
            dataKey="PN_E001"
            stroke="#c1412d"
            strokeWidth={"1.5px"}
            fillOpacity={1}
            isAnimationActive={false}
            fill="url(#pne001)"
            dots={true}
            connectNulls={true}
          />
          {/* <Line
            type="monotone"
            dataKey="Regression Line (PN-E001)"
            strokeWidth={"2px"}
            stroke="#8884d8"
            dot={false}
            isAnimationActive={false}
          /> */}
          {isPredictions && (
            <Line
              //strokeDasharray="5 5"
              dataKey="PN_E001_Predicted"
              stroke={"green"}
              strokeWidth={"1.5px"}
              dots={true}
              isAnimationActive={false} // Prevents re-draw animation
              connectNulls={true}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
