import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import IncidentsTable from "../../components/incidentstable/IncidentsTable"

// 9/3/2023
import AiWidget from "../../components/aiwidget/AiWidget"

// 9/3/2023
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react"

// 7/27/2023 - now uses a type prop to determine which data should be shown in the data table
const List = ({ tableName }) => {



  // 9/3/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  //console.log(isSidebarOpen)

  const handleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/2/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(window.innerWidth > 768 && window.innerWidth <= 1200)
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200)


  // Mobile device useeffect (<= 768)
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
        window.removeEventListener("resize", handleResize);
    };
}, []);


// Medium device useEffect
useEffect(() => {
  // Function to update state based on window size
  const handleResize = () => {
      setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
  };

  // Attach the event listener
  window.addEventListener("resize", handleResize);

  // Cleanup
  return () => {
      window.removeEventListener("resize", handleResize);
  };
}, []);

  // ========================================================================================


// Large device useEffect
useEffect(() => {
  // Function to update state based on window size
  // Auto hide the sidebar as we already have one
  const handleResize = () => {
      setIsLargeDevice(window.innerWidth > 1200);
  };

  // Attach the event listener
  window.addEventListener("resize", handleResize);

  // Cleanup
  return () => {
      window.removeEventListener("resize", handleResize);
  };
}, []);

  return (
    <div className="list">



      {/* <Sidebar/> */}

    {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
    {(isMobile || isMediumDevice) ? (
            <div className="hamburger-icon" onClick={handleSidebar}>
                <MenuIcon />
            </div>
        ) : ( // Must be a large device with a screen width > 1200px
            
            <Sidebar />
        )}


        {((isMobile || isMediumDevice) && isSidebarOpen) && <Sidebar />}


      <div className="listContainer">
        <Navbar/>
        {/* 7/28/2023 - workaround to avoid loading glitch when switching between tables in the home page */}
        {tableName === "bad_actors" ?
          <Datatable tableName={tableName}/>
          : <IncidentsTable tableName={tableName}/>
        }

        <AiWidget/>
      </div>
    </div>
  )
}

export default List