// 8/31/2023 - a widget that advertises AI

import "./aiwidget.scss";

import React from "react";
import picture from "../../ai-1.png";

// 9/23/2023 - attempting to remove flowise logo
import { useEffect } from "react";

const AiWidget = () => {
  useEffect(() => {
    // 10/1/2023 - check if script already exists
    // If not, add it there
    const isFlowisePresent = document.getElementsByTagName("flowise-chatbot");
    if (
      isFlowisePresent.length === 0 || // document.getElementsByTagName returns an HTML collection/list - no tag found
      isFlowisePresent === null ||
      isFlowisePresent === undefined
    ) {
      // Create the flowise chatbot widget and add it to the page
      const script = document.createElement("script");
      script.type = "module"; // prevent syntax error when exporting
      script.async = true;
      script.textContent = `
        import Chatbot from "https://cdn.jsdelivr.net/gh/namong19/FlowiseChatEmbed@latest/dist/web.js";
        Chatbot.init({
            chatflowid: "a1072faf-0714-4a77-a5d2-91120f87c695",
            apiHost: "https://flowise-b7du.onrender.com",
        });
      `;

      // Make it appear in the bottom right corner of the page
      document.body.appendChild(script);

      // Optional: Remove the script on component unmount to avoid potential multiple appends
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="aiwidget">
      <span className="adtitle">FortiorAI Assistant at your service!</span>
      <img src={picture} className="image" alt="placeholder" />
    </div>
  );
};

export default AiWidget;
