import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./error.scss";


// 9/1/2023 - added admin list imports
import { 
  pne001AdminList, 
  pnm001AdminList, 
  pns001AdminList 
} from "../../adminlist";
import { useNavigate } from "react-router-dom";

// 7/16/2023 - changed balance to products

// 7/24/2023 - removed orders and earnings widgets

// 8/19/2023 added imports for table names


// 9/3/2023
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from "react";

const Error = () => {


  // Code for sending the user back to their own DB:
  // on clicking the button, the user will be reassigned to their own DB based on their presence in the admin list.
  // 1) Check user's ID against admin lists
  // 2) set local storage (clientName) to the correct DB
  // navigate back to the user's home page
  const navigate = useNavigate();
  const sendUserBack = () => {
    // Get the logged in user's UID - compare this against the admin lists.
    const userUID = JSON.parse(localStorage.getItem("user")).uid

  
    // Set the clientName to the correct value based on their presence in one of the admin lists.
    if (pne001AdminList.includes(userUID)) {
      localStorage.setItem("clientName", "PN-E001")
    } else if (pnm001AdminList.includes(userUID)){
      localStorage.setItem("clientName", "PN-M001")
    } else if (pns001AdminList.includes(userUID)) {
      localStorage.setItem("clientName", "PN-S001")
    }
  
    // Send the user back to the home page.
    navigate("/")

  }

  // 9/2/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/2/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(window.innerWidth > 768 && window.innerWidth <= 1200)
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200)

  // ========================================================================================

  // Mobile device useeffect (<= 768)
  useEffect(() => {
      // Function to update state based on window size
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      // Attach the event listener
      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

      // ========================================================================================


  // Medium device useEffect
  useEffect(() => {
      // Function to update state based on window size
      const handleResize = () => {
          setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
      };

      // Attach the event listener
      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

      // ========================================================================================


  // Large device useEffect
  useEffect(() => {
      // Function to update state based on window size
      // Auto hide the sidebar as we already have one
      const handleResize = () => {
          setIsLargeDevice(window.innerWidth > 1200);
      };

      // Attach the event listener
      window.addEventListener("resize", handleResize);

      // Cleanup
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

  return (
    <div className="error">
      {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
      {(isMobile || isMediumDevice) ? (
                <div className="hamburger-icon" onClick={handleSidebar}>
                    <MenuIcon />
                </div>
            ) : ( // Must be a large device with a screen width > 1200px
                
                <Sidebar />
        )}


        {((isMobile || isMediumDevice) && isSidebarOpen) && <Sidebar />}
      <div className="errorContainer">

        <Navbar />
        <div className="subContainer">
          <h1 className="errortext">Sorry, you do not have permission to access this database.</h1>
          <p className="belowtext">Please click the Contact Admin link on the left side to request access.</p>
          <div className="returnButton">
            <button onClick={sendUserBack}>
              Go Back
            </button>
          </div>

        </div>


      </div>
    </div>
  );
};

export default Error;
