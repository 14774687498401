// 7/25/2023 - now shows the bad actor's name
export const badActorColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "displayName",
    headerName: "Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.displayName}
        </div>
      );
    },
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 100,
  },

  // 7/14/2023 - changed age to address
  {
    field: "address",
    headerName: "Address",
    width: 200,
  },

  // Age
  {
    field: "age",
    headerName: "Age",
    width: 100,
  },

  // Country
  {
    field: "country",
    headerName: "Country",
    width: 100,
  },

  // 9/29/2023 - Phone number
  {
    field: "phone",
    headerName: "Phone Number",
    width: 120,
  },

  // 9/9/2023 - added timestamp to show the date the bad actor was added
  {
    field: "addedTimeStamp",
    headerName: "Date added",
    width: 200,
  },

  // 8/13/2023 - added timestamp to show the date the bad actor was last updated
  // 8/18/2023 - renamed Date Added to Last Updated since each time the bad actor is updated, the timestamp also changes.
  {
    field: "timeStamp",
    headerName: "Last Updated",
    width: 200,
  },

  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];

// 7/27/2023 - incident columns
// 8/19/2023 - renamed ID to Incident ID to prepare to add a bad actor ID
export const incidentColumns = [
  { field: "id", headerName: "Incident ID", width: 100 },
  {
    field: "victim",
    headerName: "Victim",
    width: 150,
    // 8/20/2023
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.victim}
        </div>
      );
    },
  },
  {
    field: "crimeCategory",
    headerName: "Crime Category",
    width: 150,
  },
  {
    field: "crimeMethod",
    headerName: "Method",
    width: 100,
  },
  // 9/4/2023 - changed duration (seconds) to duration (minutes)
  {
    field: "duration",
    headerName: "Duration (minutes)",
    width: 180,
  },

  // Number of Perps
  {
    field: "numberOfPerps",
    headerName: "Number of Perps",
    width: 150,
  },

  // stolen items
  {
    field: "stolenItems",
    headerName: "Stolen Items",
    width: 100,
  },

  // 9/9/2023 - added timestamp to show the date the bad actor was added
  {
    field: "addedTimeStamp",
    headerName: "Date added",
    width: 200,
  },

  // 8/13/2023 - added timestamp to show the date the incident was added
  {
    field: "timeStamp",
    headerName: "Last Updated",
    width: 250,
  },
];

// //temporary data
// export const userRows = [
//   {
//     id: 1,
//     username: "Snow",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     status: "active",
//     email: "1snow@gmail.com",
//     age: 35,
//   },
//   {
//     id: 2,
//     username: "Jamie Lannister",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "2snow@gmail.com",
//     status: "passive",
//     age: 42,
//   },
//   {
//     id: 3,
//     username: "Lannister",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "3snow@gmail.com",
//     status: "pending",
//     age: 45,
//   },
//   {
//     id: 4,
//     username: "Stark",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "4snow@gmail.com",
//     status: "active",
//     age: 16,
//   },
//   {
//     id: 5,
//     username: "Targaryen",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "5snow@gmail.com",
//     status: "passive",
//     age: 22,
//   },
//   {
//     id: 6,
//     username: "Melisandre",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "6snow@gmail.com",
//     status: "active",
//     age: 15,
//   },
//   {
//     id: 7,
//     username: "Clifford",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "7snow@gmail.com",
//     status: "passive",
//     age: 44,
//   },
//   {
//     id: 8,
//     username: "Frances",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "8snow@gmail.com",
//     status: "active",
//     age: 36,
//   },
//   {
//     id: 9,
//     username: "Roxie",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "snow@gmail.com",
//     status: "pending",
//     age: 65,
//   },
//   {
//     id: 10,
//     username: "Roxie",
//     img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
//     email: "snow@gmail.com",
//     status: "active",
//     age: 65,
//   },
// ];
