// 7/13/2023 - changed ids
// 7/25/2023 - changed to point to bad actor form

/*

Bad actors have 

- First and last name
- A photo
- Address
- Age
- Gender
- Date added to the database (timestamp)

*/
export const userInputs = [
  {
    id: "displayName",
    label: "Name and surname",
    type: "text",
    placeholder: "John Doe",
  },
  {
    id: "gender",
    label: "Gender",
    type: "text",
    placeholder: "male",
  },
  {
    id: "age",
    label: "Age",
    type: "number",
    placeholder: "21",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "USA",
  },

  // 8/25/2023 - bad act field for form
  {
    id: "badAct",
    label: "Crime",
    type: "text",
    placeholder: "Theft",
  },

  // 9/29/2023 - Bad actor's place of employment
  {
    id: "employer",
    label: "Employer",
    type: "text",
    placeholder: "Employer",
  },

  // 9/29/2023 - Bad actor's phone number
  {
    id: "phone",
    label: "Phone Number",
    type: "text",
    placeholder: "Phone number",
  },

  // // 9/12/2023
  // {
  //   id: "deleteThreshold",
  //   label: "Self-Destruct Time (seconds)",
  //   type: "number",
  //   placeholder: "10",
  // },

  // // 9/7/2023 - bad act field for form
  // {
  //   id: "history",
  //   label: "Offense history",
  //   type: "text",
  //   placeholder: "Enter history here",
  // }
];

// Incident inputs
export const productInputs = [
  {
    id: "victim",
    label: "Victim",
    type: "text",
    placeholder: "Shell",
  },
  {
    id: "crimeCategory",
    label: "Crime Category",
    type: "text",
    placeholder: "Theft",
  },
  {
    id: "crimeMethod",
    label: "Crime Method",
    type: "text",
    placeholder: "Vehicle",
  },
  // 9/4/2023 - changed duration (seconds) to duration (minutes) and placeholder from 100 to 10
  {
    id: "duration",
    label: "Duration (minutes)",
    type: "number",
    placeholder: "10",
  },
  {
    id: "numberOfPerps",
    label: "Number of Perps",
    type: "number",
    placeholder: "1",
  },
  {
    id: "stolenItems",
    label: "Stolen items",
    type: "text",
    placeholder: "copper wire",
  },

  // 9/14/2023 - location of the incident as a real address
  {
    id: "incidentLocation",
    label: "Incident Location",
    type: "text",
    placeholder: "1234 ABC St. City State ZIP",
  },
];
