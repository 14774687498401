// 7/11/2023

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// For connecting to the DB and allowing users to login
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// 8/27/2023
import { getMessaging } from "firebase/messaging";

// 8/28/2023 - 
import emailjs from '@emailjs/browser';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "thepondnetwork-f84cf.firebaseapp.com",
  projectId: "thepondnetwork-f84cf",
  storageBucket: "thepondnetwork-f84cf.appspot.com",
  messagingSenderId: "53041408936",
  appId: "1:53041408936:web:951be262925091f9610b9d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth()

// 7/13/2023 - Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// 7/14/2023 Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

// 8/27/2023 - Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

// 7/27/2023 - firebase database names - change these as needed
export const badActorTableName = "bad_actors"
export const incidentsTableName = "incidents"


// 8/28/2023 - definition of sendNotifications function
// Trigger push notifications with a given title and body (both are strings)
export const sendNotification = (title, body) => {

    const message = {
      notification: {
        title: title, // The title of the notification
        body: body // the text of the notification
      },
    };

    const notificationOptions = {
        body: message.notification.body
    };

    // Create the notification
    let notification = new Notification(message.notification.title, notificationOptions)
    
    // Close the notification when clicked
    notification.onclick = (e) => {
      e.preventDefault();
      notification.close();
    }

}

// 8/28/2023 - send an email when a database is modified


export const sendEmail = (template_key) => {

  let templateParams = {
    name: 'Pond Network',
    notes: "From the boss"
  }

  // arguments to sendForm are: service key, email template key, the form, and the public key.
  // emailjs.sendForm('service_e824huj', template_key, null, 'bntucwAhtAI0XWhDP')
  emailjs.send('service_e824huj', template_key, templateParams, 'bntucwAhtAI0XWhDP')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });


  console.log("Email successfully sent to admin")
};