import React from 'react'
import "./videos.scss";

const Videos = ({ incidentVideos }) => {
    // Show nothing if the array is not an array or it is empty
    if (incidentVideos === undefined || incidentVideos === "") {
        return (
            <div className="videos">
                <span className="videowidgettitle">No Incident Video</span>
                <img src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg" className="placeholderImage" alt="placeholder"/>
            </div>
        );
    }
  return (
    <div className='videos'>
        <span className="videowidgettitle">Incident Video</span>
        <video controls className='incidentVideo'>
            <source src={incidentVideos} type="video/mp4"></source>
        </video>
    </div>
  )
}

export default Videos