// 7/12/2023

const AuthReducer = (state, action) => {
    switch (action.type) {
      case "LOGIN": {
        return {
          currentUser: action.payload, // get the current user
        };
      }
      case "LOGOUT": {
        return {
          currentUser: null, // nullify the current user
        };
      }
      default:
        return state;
    }
  };
  
  export default AuthReducer;
  