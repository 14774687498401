import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./contactform.scss";

import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";

// 7/16/2023 - changed balance to products

// 7/24/2023 - removed orders and earnings widgets

// 8/19/2023 added imports for table names

// 9/2/2023
import MenuIcon from "@mui/icons-material/Menu";
//import AiWidget from "../../components/aiwidget/AiWidget";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // arguments to sendForm are: service key, email template key, the form, and the public key.
    emailjs
      .sendForm(
        "service_e824huj",
        "template_qy71hk5",
        form.current,
        "bntucwAhtAI0XWhDP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    console.log("Email successfully sent to admin");

    // 9/5/2023 - update state
    setIsMessageSent(true);
  };

  // 9/2/2023 - New state variable to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // 9/5/2023 - check if message was sent to admin
  const [messageSent, setIsMessageSent] = useState(false);

  // 9/2/2023 - new state variable to check device size
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumDevice, setIsMediumDevice] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1200
  );
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth > 1200);
  console.log(isLargeDevice);

  // ========================================================================================

  // Mobile device useeffect (<= 768)
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Medium device useEffect
  useEffect(() => {
    // Function to update state based on window size
    const handleResize = () => {
      setIsMediumDevice(window.innerWidth > 768 && window.innerWidth <= 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ========================================================================================

  // Large device useEffect
  useEffect(() => {
    // Function to update state based on window size
    // Auto hide the sidebar as we already have one
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth > 1200);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(isMobile);

  // HTML starts below ========================================================================================

  return (
    <div className="contactform">
      {/* On mobile and medium devices, show the hamburger menu. On larger devices, show the sidebar */}
      {isMobile || isMediumDevice ? (
        <div className="hamburger-icon" onClick={handleSidebar}>
          <MenuIcon />
        </div>
      ) : (
        // Must be a large device with a screen width > 1200px
        <div className="sidebar">
          <Sidebar />
        </div>
      )}

      {(isMobile || isMediumDevice) && isSidebarOpen && (
        <div className="sidebar open">
          <Sidebar />
        </div>
      )}

      <div
        className={`contactformContainer ${
          isSidebarOpen ? "with-sidebar" : ""
        }`}
      >
        <Navbar />
        <h1 className="titletext">Contact Us</h1>
        <div className="contactformBody">
          <form ref={form} onSubmit={sendEmail}>
            <div className="formGroup">
              <input type="text" name="user_name" placeholder="Name" required />
            </div>
            <div className="formGroup">
              <input
                type="email"
                name="user_email"
                placeholder="Email"
                required
              />
            </div>
            <div className="formGroup">
              <input
                type="text"
                name="database_name"
                placeholder="Database Name"
                required
              />
            </div>
            <div className="formGroup">
              <textarea name="message" placeholder="Your Message" required />
            </div>
            <input className="submitInput" type="submit" value="Send" />

            {/* If a message was sent successfully, show a message */}
            {messageSent && (
              <span className="success">Email sent to admin</span>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
