// 9/11/2023 - modal to show when the user clicks the delete button in the table
// The modal will show a message and two buttons (delete and cancel)
// Cancel will close the modal
// Delete will delete the data

import React from 'react'
import "./deletemodal.scss";

const DeleteModal = ({ deleteEntry, closeModal }) => {
  return (
    <div className='deleteModal'>
        <div className="modal">
            {/* Message */}
            <div className="top">
                <span>
                Are you sure?
                </span>
            </div>
            {/* Buttons */}
            <div className="bottom">
                <div
                    className="deleteButton"
                    onClick={deleteEntry}
                >
                    Delete
                </div>
                <div
                    className="cancelButton"
                    onClick={closeModal}
                >
                    Cancel
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default DeleteModal