import "./widget.scss";
//import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import PlaceIcon from "@mui/icons-material/Place";

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

// 7/16/2023
import {
  collection,
  query,
  //where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";

// 8/21/2023

const Widget = ({ type }) => {
  const [amount, setAmount] = useState(null);
  //const [diff, setDiff] = useState(null);

  let data;

  const clientName = localStorage.getItem("clientName");

  switch (type) {
    case "bad_actors":
      data = {
        title: "PERSONS OF INTEREST",
        isMoney: false,
        link: "See all persons of interest",
        path: "bad_actors",
        query: `Clients/${clientName}/bad_actors`,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "incidents":
      data = {
        title: "INCIDENTS",
        isMoney: false,
        link: "View all incidents",
        path: "incidents",
        query: `Clients/${clientName}/incidents`,
        icon: (
          <PlaceIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  // 7/16/2023 - useEffect for querying data
  useEffect(() => {
    const fetchData = async () => {
      // // Take today's date
      // const today = new Date();
      // const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
      // const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

      // console.log(lastMonth)
      // console.log(prevMonth)

      // // Get data from last month
      // // Example: if today is July 16, get data between June 16 and today
      // const lastMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", today),
      //   where("timeStamp", ">", lastMonth),
      // );

      // // get data from the last two months
      // // If today is July 16, get data between June 16 and May 16
      // const prevMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", lastMonth),
      //   where("timeStamp", ">", prevMonth)
      // );

      // // Get the data
      // const lastMonthData = await getDocs(lastMonthQuery);
      // const prevMonthData = await getDocs(prevMonthQuery);

      // get data about the
      // If today is July 16, get data between June 16 and May 16
      const widgetQuery = query(collection(db, data.query));

      const widgetData = await getDocs(widgetQuery);

      // Set the user count in the widget
      setAmount(widgetData.docs.length); // number of users total
      //setAmount(lastMonthData.docs.length) // number of users total
      // Set the percentage value in the widget
      // setDiff(
      //   ((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) *
      //   100
      //   )
    };
    fetchData();
  }, []);

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {amount}
        </span>
        {/* 8/19/2023 - Links in the widget now work */}
        <Link to={`/${data.path}`} style={{ textDecoration: "none" }}>
          <span className="link">{data.link}</span>
        </Link>
      </div>
      {/* Updated 7/16/2023 - text color and arrow icon depends on the diff value being positive or negative.*/}
      {/* 9/4/2023 - removed the percentage difference  */}
      <div className="right">
        {/* <div className={`percentage ${diff < 0 ? "negative" : "positive"}`}>
          {diff < 0 ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon />}
          {diff} %
        </div> */}
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
