// 7/11/2023
// Updated to use an actual page
import { useContext, useState } from "react";
import "./login.scss";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import { legalText } from "../../legal";

// 7/25/2023 -> Testing firebase with nested collections
import {
  collection,
  //deleteDoc,
  //doc,
  onSnapshot,
  //getDocs
} from "firebase/firestore";

import { useEffect } from "react";

// 7/14/2023
import { db } from "../../firebase";
import { Checkbox, FormControlLabel } from "@mui/material";

const Login = () => {
  const [error, setError] = useState(false);
  const [isClientSelected, setIsClientSelected] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState([]);
  const [client, setClient] = useState("");
  const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false);

  const navigate = useNavigate();

  // For demo - hardcoding list of client names
  //const clientList = ["Exxon", "Mobil", "Shell"]

  // 9/8/2023 - currnet client list
  const clientList = ["PN-E001", "PN-M001", "PN-S001"];

  // 7/25/2023
  useEffect(() => {
    // LISTEN (REAL TIME DATA)
    const unsub = onSnapshot(
      collection(db, "Clients"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        //console.log(list)
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    // Return a cleanup function to prevent memory leaks from having the function called again and again
    return () => {
      unsub();
    };
  }, []);

  // 7/12/2023
  // dispatch is for logging in
  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault(); // do not refresh the page

    if (client === "") {
      setIsClientSelected(false);
    } else {
      // client is selected
      setIsClientSelected(true);

      // 8/23/2023 - How to check if the logged in user has read access?

      if (!acceptedDisclaimer) {
        setAcceptedDisclaimer(false);
      } else {
        // log in with the provided email and password.
        // redirect the user to the home page.
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            dispatch({ type: "LOGIN", payload: user }); // 7/12/2023
            localStorage.setItem("clientName", client); // 8/16/2023 - set the client name to use later
            navigate("/", { state: { clientName: client } });
          })
          .catch((error) => {
            setError(true);
            // ..
          });
      }
    }
  };

  const handleChange = (e) => {
    setClient(e.target.value);
  };

  // User either accepts or declines the terms and conditions
  const handleAcceptDisclaimer = (e) => {
    setAcceptedDisclaimer(e.target.checked);
  };

  //console.log(client)

  return (
    // Primary div to hold the hero and login divs
    // The hero div will contain the hero banner
    <div className="loginMain">
      <div className="hero">
        <div className="heroImage" />
        <div className="heroText">
          <h1>The Pond Network</h1>
        </div>
      </div>
      <div className="login">
        <form onSubmit={handleLogin}>
          <input
            className="loginFormField"
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="loginFormField"
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {/* Dropdown to select which client data to view */}
          <select name="clients" onChange={handleChange} required>
            <option key="default" value="Select Client">
              Select Client
            </option>
            {clientList.map((client) => (
              <option key={client} value={client}>
                {client}
              </option>
            ))}
          </select>

          <br />

          {/* Disclaimer text - to be read before logging in */}
          <div className="disclaimerText">
            {legalText.map((paragraph, i) => (
              <p
                key={i}
                style={
                  i === 0 || i === legalText.length - 1
                    ? { "font-weight": "bold", marginBottom: "16px" }
                    : { marginBottom: "16px" }
                }
              >
                {paragraph}
              </p>
            ))}
          </div>

          {/* Checkbox */}
          <div className="loginCheckbox">
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { color: "black" } }}
                  onChange={handleAcceptDisclaimer}
                  required
                />
              }
              label="I accept the terms and conditions."
              className="whiteLabel"
            />
          </div>

          <button type="submit">Login</button>
          {/* 7/25/2023 -changed error message */}
          {error && <span className="errorText">Wrong Email or password!</span>}
          {/* Additional error message if the client is not selected */}
          {!isClientSelected && (
            <span className="errorText">Please select a client!</span>
          )}
        </form>

        {/* End login div */}
      </div>
      {/* End main div */}
    </div>
  );
};

export default Login;
